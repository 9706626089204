app-mindmap-list {
    
    .close {
        @extend %button--square-icon !optional;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        margin: 18px 12px;
    }

    .mat-progress-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        width: 100% !important;
        svg {
            position: relative;
            circle {
                stroke: var(--accent);
            }
        }
    }
    .mindmap-card-list {
        flex-wrap: wrap;
        overflow-y: auto;

        .fake-card .mat-card {
            display: flex;
            justify-content: center;
            background: $accent-c;
            border-radius: 13.9941px;
            min-height: 278px;
            height: auto;
            color: white;
        }

        .fake-card .mat-card-content {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-left: 0 !important;
        }

        .fake-card .mat-card-content mat-icon {
            height: 57px;
            width: 57px;
        }
    }
}

vertical-layout-1 #main > .container > .container > .container content > app-mindmap-content-editor > .page-layout > .content {
    padding: 0 !important;
}

app-mindmap-content-editor {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .content-editor {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        place-content: stretch flex-start;
        align-items: stretch;

        .mindmap-header {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            padding-block: 15px;
            padding-inline: 20px;
            font-size: 20px;

            .close {
                @extend %button--square-icon !optional;
            }
        }

        .mindmap-content {
            height: 100%;
            flex: 1 1 auto;

            iframe {
                border : 0;
                height: 100%;
            }
        }
    }
}


mat-dialog-container.mat-dialog-container app-mindmap-data-editor-modal {
    .mat-dialog-content {

        form {
            padding: 0 10px 10px 10px;

            mat-list-item {
                mat-spinner {
                    margin: 0;
                }
            }
        }

        mat-spinner {
            margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the modal
        }
    }
}


app-mindmap-card.card-split-media-info {
    mat-card-content {
        height: 100%;

    }
    mat-card-actions.mat-card-actions  {
        place-content: flex-end;
    }

}

.mindmap-listing-modal .page-layout,
.mindmap-listing-modal .page-layout .content,
.mindmap-content-editor-dialog .page-layout,
.mindmap-content-editor-dialog .page-layout .content {
    height: 100%;
    max-height: 100%;
}

app-mindmap-card mat-chip {
    mat-icon {
        color: #037CC2 !important;
        font-size: 20px;
    }

    div {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

}