gamification {
    mat-ink-bar, .mat-tab-header {
        display: none !important;
    }

    .mat-tab-label-content {
        display: none !important;
    }

    .credits, .icon {
        z-index: 2;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        letter-spacing: 0.1px !important;
        color: #000000 !important;

        mat-icon {
            height: 38px !important;
            width: 38px !important;
        }
    }

    .credits > .icon {
        background: none !important;
    }

    .mat-tab-labels {
        background-color: #E8EDF6;
    }

    .mat-tab-body-content {
        transform: none;
        padding: 75px 400px 150px 400px;
        background-color: #e8edf6;
    }

    app-my-animals {
        padding: 0;
        background: #F9FBFF;
        border: 6px solid #28458C;
        box-shadow: inset 0 4px 10px rgba(255, 255, 255, 0.7);
        filter: drop-shadow(0 7px 20px rgba(0, 0, 0, 0.15));
        border-radius: 15px;
    }

    .animal-container {
        border-radius: 8px;
    }

    app-animal-controls {
        height: calc(100% + 15px) !important;

        .inner-container {
            position: absolute;
            top: -57px;
            background: #28458C;
            border: 3px solid #FBAC35;
            border-radius: 5px;
            height: 70px;

            .name {
                background: #28458C !important;

                .text {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                }
            }
        }

        .xp-bar-container, .set-avatar, .arrow {
            display: none !important;
        }

        .popup-container {
            position: absolute;
            bottom: -90px;
            left: -400px;
        }

        button.download {
            bottom: -90px;
            height: 50px;
        }

        button.help {
            bottom: 42px;
            height: 50px;
        }

        button {
            position: absolute !important;
            background: #FBAC35 !important;
            border: 0.663143px solid rgba(0, 0, 0, 0.05);
            box-shadow: inset 0 5px 5px rgba(255, 255, 255, 0.05);
            border-radius: 5px;

            span {
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
            }
        }

        app-accessories-popup .main-container .customize-container .customize {
            background: #FBAC35 !important;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner .buttons .button .inner-button.enabled {
            background: #FBAC35 !important;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner .buttons .button .inner-button.enabled.reset {
            background: #F9FBFF !important;
            color: #3A4354;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .tabs > .tab {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #3A4354;
            text-align: center;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .tabs {
            background: #FFFFFF;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .tabs > .tab.active {
            color: #FFFFFF;
        }

        app-accessories-popup .main-container .popup-container > .popup > .close {
            top: -19px !important;
            right: -19px !important;
            z-index: 10;
        }

        .container.selected {
            border-color: #FBAC35 !important;
        }

        .pop-up-opened {
            .popup-container {
                position: absolute;
                left: -400px !important;
                top: -220px;

                .tabs {
                    z-index: 2; // solve mistake on menu click when univers are scroll
                }
            }

            app-accessories-popup {
                height: 100%;
                position: fixed;
                left: 15px;

                .ps__rail-y .ps__thumb-y {
                    background-color: #FBAC35 !important;
                }

                .items-container {
                    max-height: 70vh !important;

                    app-accessory-thumbnail {
                        .container {
                            box-shadow: 0 16px 23px rgba(0, 0, 0, 0.04), inset 0 7px 12px #FFFFFF;
                        }

                        .locked-icon {
                            height: 30px !important;
                            width: 30px !important;
                        }

                        .unlockable {
                            .unlock {
                                flex-direction: row !important;
                                align-items: center !important;
                                justify-content: space-around !important;
                                background: #28458C !important;
                                border: 1px solid rgba(10, 0, 0, 0.04) !important;
                                border-radius: 24px !important;
                                height: 20px !important;
                                width: auto !important;
                                padding-left: 5px;

                                mat-icon {
                                    width: 16px !important;
                                    height: auto !important;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                }

                .main-container .popup-container > .popup > .popup-inner > .items-container > .items.accessories {
                    display: flex !important;
                    flex-direction: row !important;
                    flex-wrap: wrap !important;
                    width: 400px !important;
                    justify-content: space-between !important;
                }

                .main-container .popup-container > .popup > .popup-inner > .items-container > .items.universes app-accessory-thumbnail {
                    width: 400px !important;
                }
            }
        }

        .pop-up-closed {
            app-accessories-popup {
                height: auto;
                width: 300px;
            }

            .main-container {
                width: 80px;
                height: 80px;
            }

            mat-icon {
                height: 38px !important;
                width: 38px !important;
            }
        }

        app-accessories-popup {
            .popup-container {
                width: auto !important;
                border-radius: 15px;
                left: 0;
                padding-right: 50px;
                background: transparent;
                align-items: flex-start;
                display: block !important;
                top: 0;
                padding-top: 50px;

                .customize {
                    background: #FBAC35 !important;
                }

                .popup {
                    width: auto !important;
                    height: auto !important;
                    background: #E8EDF6;

                    .popup-inner > .tabs > .tab.active {
                        background: #FBAC35 !important;
                    }
                }

                .popup-inner {
                    border: 3px solid #FBAC35 !important;
                }

                .close {
                    background: #FBAC35 !important;
                }
            }

            .customize-container {
                align-items: center !important;
                flex-direction: column !important;
                width: 80px;
                height: 80px;
                background: #FFFFFF;
                border-radius: 15px;
            }
        }
    }

    app-buy-popup {
        .container {
            padding: 0 0 20px 0 !important;
        }

        .close-button-container {
            background: #28458C;
            color: white;
            border-radius: 10px 10px 0 0;
            padding: 5px 5px 0px 5px;
        }

        .button-container {
            .button {
                background: #FBAC35 !important;
                border: 1px solid rgba(0, 0, 0, 0.1) !important;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
                border-radius: 5px !important;
            }
        }
    }

    .mat-tab-body-content {
        background-image: url('/assets/amazon_python/icons/gamification_background.svg') !important;
        background-repeat: no-repeat;
        background-position: center;
    }
}

mat-dialog-container.mat-dialog-container {
    rewards-dialog {
        .mat-dialog-title {
            margin-bottom: 0;
        }

        .mat-dialog-content {
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            align-content: stretch;
        }

        .content {
            dl {
                dt::after {
                    content: "";
                }
                dt {
                    font-weight: bold;
                    width: 60%;
                    text-align: right;
                    float: left;
                }
                dt, dd{
                    height: 50px;
                }
            }

            padding: 10px;
            width: 100%;
            text-align: center;

            .earning {
                font-weight: bold;
                font-size: 27px;
                font-style: italic;

                mat-icon {
                    vertical-align: bottom;
                    width: 40px;
                    height: 40px;
                }

                .badge {
                    aspect-ratio: 1/1;
                    background-color: #fff;
                    border: 0.1rem solid #c6ccd7e5;
                    border-radius: 0.75rem;
                    box-shadow: 0 9px 13px 0 rgb(0 0 0 / 1%);
                    padding: 1px 1px;
                    width: 50px;
                    height: 50px;
                    display: inline;
                    vertical-align: middle;

                }
            }
        }

        .activity-image {
            margin: 0;
            display: flex;
            background: radial-gradient(65.09% 65.09% at 50% 50%, #39E07C 0%, #58A5CF 100%);
            border: 5px solid #05A8AA;
            box-shadow: -4px 5px 10px rgb(0 0 0 / 25%), inset 0px 0px 52px rgb(0 0 0 / 50%);
            max-width: 40%;

            img {
                max-width: 296px;
                max-height: 275px;
                padding: 20px;
            }
        }


    }
}
