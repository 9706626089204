.ck{
  i{
    font-size: inherit;
    width: inherit;
    height: inherit;
    min-width: inherit;
    min-height: inherit;
    line-height: inherit;
    color: inherit;
  }
}
