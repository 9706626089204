.container > content,
.container > content.mat-white-bg {
    flex: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--fond_ecran);
}

body.activities content {
    background: none;
}

.mat-white-bg {
    background-color: var(--fond_ecran) !important; //override default white background
}