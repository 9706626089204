.ck-editor-output {
    text-align: center;
    i {
        font-size: inherit;
    }

    p {
        font-size: inherit;
    }

    ul, ol {
        text-align: left;
        li {
            border: none;
            padding: 0;
            background-color: transparent;
        }
    }
}