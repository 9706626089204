@use '@angular/material' as mat;

#login div#login-form-wrapper #login-form,
div#forgot-password-form,
#register #register-form {
    // Alternative Material theme
    $form-theme: mat-light-theme($primary, $accent, $warn-alt);
    @include mat.all-legacy-component-themes($form-theme);
    // Layout & style
    height: 80vh;
    overflow: hidden;
    color: $fuse-authentication-color;
    background: image('backgrounds/bg_popup_dialog.jpg') top left / cover no-repeat !important;
    border-radius: #{$fuse-authentication-border-radius};
    padding: 32px 32px 142px 32px;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: underline;
    }

    > .submit-button {
        margin: 4em 0 2em;
    }

    .title {
        flex: 1;
        font: #{$fuse-authentication-title-font};
        letter-spacing: #{$fuse-authentication-title-letter-spacing};
        margin: 0;
    }

    form {
        flex: 4;
        display: flex;
        flex-direction: column;
    }

    .mat-form-field-label {
        color: black;
        font-weight: normal;
        transition: color 0.5s, font-weight 0.5s;
    }

    .mat-checkbox {
        font-size: 14px;

        .mat-checkbox-layout {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            .mat-checkbox-inner-container { // la case
                flex: none; // ne s'étend pas

                .mat-checkbox-background {

                    .mat-checkbox-checkmark {

                        .mat-checkbox-checkmark-path {
                            stroke: var(--typo) !important; // Override Material style
                        }
                    }
                }
            }

            .mat-checkbox-label {
                white-space: normal;
                display: flex;
                flex-wrap: wrap; // les enfants vont à la ligne
                flex: 1; // j'occupe tout l'espace dispo
            }
        }
    }

    jhi-re-captcha {
        margin-top: 20px;
    }

    .mat-checkbox-frame {
        border-color: map_get($mat-fusedark, 100);
    }

    .mat-form-field-appearance-legacy {

        .mat-form-field-wrapper {

            .mat-form-field-underline {
                display: none;
            }
        }

        .mat-form-field-flex {
            margin-top: 1.8em;

            background-color: #fff;
            border-radius: #{$fuse-authentication-field-infix-border-radius};
            border: none;
        }

        .mat-form-field-infix {
            padding: #{$fuse-authentication-field-infix-padding};
            border: none;

            .mat-form-field-label-wrapper {
                padding: 0;
                top: -0.5em;

                .mat-form-field-label {
                    text-transform: inherit;
                    padding-left: 0;
                    padding-right: 10px;
                    font-family: #{$font-family};
                }
            }
        }

        &.mat-form-field-can-float.mat-form-field-should-float {
            .mat-form-field-label-wrapper {
                top: -1.3em;
                padding: 0;

                .mat-form-field-label {
                    transform: translateY(-1.5em) scale(1);
                    color: white;
                }
            }

        }

        .mat-form-field-prefix, .mat-form-field-suffix {
            align-self: center;
            padding-right: 10px;
            color: #{$primary-c};
        }

        .mat-input-element, .mat-select {
            font: #{$fuse-authentication-field-input-font};
            font-size: 18px;
            background-color: transparent !important;
            color: black;

            margin-bottom: 4px;
            margin-top: 6px;
        }

        /* Change the white to any color ;) */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
        }

        input:-internal-autofill-selected {
            background-color: transparent !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, transparent) !important;
        }

        .mat-form-field-label {

        }

        .mat-form-field-underline,
        .mat-form-field-ripple,
        .mat-focused .mat-form-field-ripple,
        .mat-form-field-underline,
        .mat-form-field-ripple,
        .mat-focused .mat-form-field-ripple {
            margin-top: 5px;
            background-color: transparent;
        }

    }

    button.submit-button {

        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        &:disabled {
            opacity: 40%;

            span {
                cursor: not-allowed;
            }
        }
    }

    .you-are {
        order: -1;
    }

    .region {
        order: 3;
    }

    .level-input, .level-input-hide {
        order: 4;
    }

}

#login, #forgot-password {

    div#login-form-wrapper, div#forgot-password-form-wrapper { // TODO supprimer de default pour s'en passer ici

        #login-form, #forgot-password-form {
            position: relative;
            width: $fuse-authentication-login-width; // 384px;
            max-width: $fuse-authentication-login-width;
            min-height: 465px;

            .logo {
                flex: 1 1 160px;
                margin: -32px 0 0 -32px;
                width: calc(100% + 64px);
                background: url('/assets/sapiens/icons/ripped_paper_2.svg') 50% 100% no-repeat !important;
                box-shadow: inset 0 9vh 0 rgb(255, 255, 255);
                display: flex;
                justify-content: center;
                max-height: 235px;

                .mat-icon {
                    width: 256px;
                    margin-bottom: 10%;
                    height: inherit;
                    min-width: inherit;
                    min-height: inherit;
                    line-height: inherit;
                }


            }

            //.title {
            //    margin: 60px 0 40px 0;
            //}

            .divider {
                margin: 2em 0;
                color: $fuse-authentication-color;
                font-weight: bold;
                display: block;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;

                > span {
                    text-transform: uppercase;
                    position: relative;
                    display: inline-block;

                    &:before, &:after {
                        content: "";
                        position: absolute;
                        top: 45%;
                        width: 9999px;
                        height: 2px;
                        background: $fuse-authentication-color;
                    }

                    &:before {
                        right: 100%;
                        margin-right: 15px;
                    }

                    &:after {
                        left: 100%;
                        margin-left: 15px;
                    }
                }
            }

            .register {
                padding: 0;
                margin: 0;
                position: absolute;
                bottom: 110px;
                width: calc(100% - 64px);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
        }

    }
}

#register {

    #register-form {
        height: unset;
        padding: 32px;

        .mat-form-field-flex {
            margin: 30px 0 0;
        }

        .you_are {
            order: 1;
        }

        .pseudo {
            order: 2;
        }

        .field-email {
            order: 3;
        }

        .field-password {
            order: 4;
        }

        .region {
            order: 5;
        }

        .level-input,
        .level-input-hide {
            order: 6;
        }

        .checkboxes {
            order: 7;
            margin: 60px auto 0;

            .mat-checkbox {
                margin: 10px 0 0 !important;

                &:first-child {
                    margin: 0 !important;
                }
            }

            .submit-button {
                position: unset;
                bottom: unset;
                left: unset;
                transform: none;
                margin: 60px auto 0;
            }
        }

        .register {
            order: 8;
            margin: 10px auto 0;
        }
    }
}

#forgot-password mat-form-field {
    padding: 20px !important;
}

#forgot-password #forgot-password-form-wrapper #forgot-password-form {
    padding: 0 !important;
    .logo {
        margin: 0 !important;
    }
}

#register-form {
    padding-bottom: 60px;

    .logo {
        display: none !important;
    }

    .title {

    }

    .logo + div {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .mat-form-field {
        padding: 0 5px;


        &.field-password {
            max-width: 100% !important;
        }

    }
}

#forgot-password-form {
    width: 483px;
    max-width: 100%;

    .logo {
        width: auto !important;
        margin: 62px 0 0 0 !important;

        .mat-icon {
            width: 274px;
            height: 80px;
            min-width: inherit;
            min-height: inherit;
            line-height: inherit;
        }

    }
}

fuse-password-reset-email,
fuse-mail-confirm {

    a {
        color: var(--typo);
        text-decoration: underline;
    }
}