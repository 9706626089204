@use '@angular/material' as mat;

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.67);
    backdrop-filter: blur(5px);
}

.mat-menu-panel {
    .mat-menu-item {
        font: 700 12px/16px "Quicksand", "Roboto", sans-serif;

        .mat-icon {
            display: none;
        }
    }
}

.mat-dialog-container {
    position: relative!important;
}
.close.mat-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    line-height: 14px;
    min-width: auto;
}




.mat-dialog-title,
mat-dialog-container.mat-dialog-container .mat-dialog-title,
.activities-dialog-title {
    background: $primary-c;
    min-height: 44px;
    padding: 14px 14px 11px 21px;
    font-size: 18px;
    font-weight: bold;

    h1, h2, h3, .h1, .h2, .h3,
    &h1, &h2, &h3
    {
        font-size: 18px;
        font-weight: bold;
    }


    button {

        &:not(.feedback__close-button) {
            display: flex;
            width: 16px;
            height: 16px;
            background-color: transparent !important;
            color: map_get($mat-white, 500);

            &.close {
                align-items: center;
                justify-content: center;
                width: 33px;
                height: 33px;
                padding: 8px;
                background-color: #fbac35 !important;
                border-radius: 5px;

                .mat-icon {
                    transform: none;
                }
            }


            .mat-icon {
                width: 100% !important;
                height: 100% !important;
                min-width: 100% !important;
                min-height: 100% !important;
                transform: translateY(-6px);

            }
        }
    }

    svg, button.mat-mini-fab.mat-accent svg {
        path {
            fill: none !important;
            stroke: map_get($mat-white, 500) !important;
        }
    }

}

// Generic dialog modale
mat-dialog-container.mat-dialog-container {
    background-color: map_get($mat-white, 500);
    color: $primary-c;
    border: none;
    overflow: hidden;
    border-radius: 5px;
}

app-edit-lesson-dialog {
    .mat-form-field-infix mat-select-value {
        min-height: 44px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #000000;
    }
}

.activities-list-dialog .mat-dialog-container {
    height: 95%;
    margin-top: 1%;
    padding: 0;

    .activities-dialog-title button {
        height: 24px;
        margin-left: auto;
        width: 24px;
    }

    .mat-dialog-content {
        flex-direction: row;
    }

    .multi-content {
        flex: 1;

        .mat-card.activities-card {
            .mat-card-header {
                display: none;
            }

            .mat-card-content {
                flex: 1;
                height: 100%;
            }
        }
    }

    .preview-wrapper, .next-wrapper {
        min-width: 60px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        flex: none !important;

        .preview, .next {
            padding: 0;
            margin: 0;
            width: 32px;
            height: 32px;
            background-color: mat.get-color-from-palette($accent);

            &[disabled] {
                display: none;
            }
        }
    }

}

.edit_assignment_comment_modal {
    mat-dialog-container.mat-dialog-container .mat-dialog-actions {
        flex-direction: row-reverse; // invert button cancel and validation for this modal
    }
}

.mat-dialog-container {

    mat-card-footer {
        background: #ECECEC;

        button {
            min-width: 120px !important;

            .mat-button-wrapper {
                justify-content: center !important;
            }
        }

        button.save-button {
            @extend %main-button;

        }
    }

    app-feedback {

        .feedback {

            .feedback__header {
                padding: 2rem;
                color: white;
                background-color: var(--typo);

                .feedback__title {}

                .feedback__close-button {
                    @extend %mat-stroked-button-accent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 3.2rem;
                    height: auto;
                    aspect-ratio: 1;
                    border-radius: 4px;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

                    .mat-icon {

                        svg {

                            path {
                                stroke: currentColor !important;
                            }
                        }
                    }
                }
            }

            .feedback__content {
                color: var(--typo);
                font-size: 1.6rem;
            }

            .feedback__actions {

                .feedback__close-button {
                    @extend %mat-stroked-button-accent;
                    min-width: 12rem;
                    padding: 1rem 2rem;
                    border-radius: 20px;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    fuse-confirm-dialog {

        .confirm-dialog__header {
            color: white;
        }

        .confirm-dialog__content {
            color: var(--typo);
        }

        .confirm-dialog__actions {

            button {
                flex: none;
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 1rem 1.5rem;
                border-radius: 20px;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
                line-height: 1.2;

                .mat-button-wrapper {
                    display: contents;
                }

                .mat-icon {
                    flex: none;
                    width: 2rem;
                    min-width: unset;
                    height: auto;
                    min-height: unset;
                    aspect-ratio: 1;
                    margin: unset; // Unset default theme style

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                &.confirm-dialog__validate-button,
                &.confirm-dialog__cancel-button {

                    &::after {
                        content: '';
                        width: 2rem;
                        height: auto;
                        aspect-ratio: 1;
                        background-color: var(--typo);
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                    }
                }

                &.confirm-dialog__validate-button {

                    &::after {
                        mask-image: icon('check_rounded_700.svg');
                    }
                }

                &.confirm-dialog__cancel-button {

                    &::after {
                        mask-image: icon('cross.svg');
                        transform: scale(0.85);
                    }
                }
            }
        }
    }
}