app-timeline {
    .friseContainer {
        background-color: white;
        width: 100%;
    }

    .mainTitle {
        border: solid;
        border-color: white;
        min-width: 30%;
        position: relative;
        margin: auto;
        top: 21px;
        border-width: 1px;
        margin-bottom: 30px;
        display: inline-block;
        padding: 0 15px;
    }

    .centerInlineBlock{
        text-align: center;
    }

    .mainFirstTitleContent {
        color: rgb(255, 91, 21);
        text-align: center;
        font-size: x-large;
        margin: 0;
    }

    .mainTitleContent {
        color: $default-font-color;
        text-align: center;
    }

    button.close-timeline {
        position: absolute;
        right: 5px;
        top: 5px;

        svg path {
            stroke: white;
        }
    }
}

app-viewer {
    .parent {
        overflow: hidden;
    }

    .cadreImage {
        display: inline-block;
        height: 0;
    }

    img {
        vertical-align: inherit;
    }

    .beginTimeline {
        display: inline-block;
        margin-top:-30px;
    }

    .endTimeline {
        display: inline-block;
        margin-top:-30px;
    }

    .loupe {
        position: relative;
        left: 50%;
        top: 80%;
        display: block;
        cursor: pointer;
        background-color: white;
        border-radius: 50%;
        padding: 2px;
        height: 30px;
        width: 30px;

        svg {
            transform: scale(0.8) translate(1px, 1px);
        }
    }

    .imageContainer {
        display: inline-block;
        margin-top:-30px;
    }

    .repereCentre {
        width: 30%;
        position: relative;
        left: 44%;
        text-align: center;
    }
}

app-slider {
    .timelineContainer {
        position: relative;
        width: 100%;
        height: 100px;
        margin-top: 1%;
    }

    .popup {
        color: $default-font-color;
        height: 40px;
        width: 50px;
        position: absolute;
        top: 35%;
        margin-top: 5px;
        padding-top: 2px;
        background-color: white;
        border-radius: 30%;
        text-align: center;
    }

    .zoneColor {
        height: 60px;
        width: 1%;
        position: absolute;
        top: 0;
    }

    .splitZone {
        background-color: white;
        height: 30px;
        width: 1%;
        position: absolute;
        top: 0;
    }

    .splitInterval {
        background-color: white;
        height: 30px;
        width: 0.2%;
        position: absolute;
        top: 30px;
    }

    .splitIntervalTimeText {
        color: $default-font-color;
        height: 30px;
        width: auto;
        position: absolute;
        top: 70px;
    }

    .dateDebut {
        left: 5px;
    }

    .dateFin {
        right: 5px;
    }

    .circle {
        position: absolute;
        top: 5%;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: black;
    }

    input[type=range].range2::-webkit-slider-runnable-track {
        width: 100%;
        height: 25px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000;
        background: rgb(216, 147, 125);
        border-radius: 0;
        border: 1px solid #000000;
    }

    input[type=range].range3::-webkit-slider-runnable-track {
        width: 100%;
        height: 25px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000;
        background: rgb(245, 207, 195);
        border-radius: 0;
        border: 1px solid #000000;
    }

    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;
        position: absolute;
        padding: 4px;
    }

    /* /**remove square around thumb*/
    input[type=range]:focus {
        outline: none;
    }

    /*curseur custom chrome*/
    input[type=range]::-webkit-slider-thumb {
        height: 41px;
        width: 41px;
        border-radius: 50px;
        background-image: url('/assets/marqueblanche/icons/thumbWithArrow.svg');
        fill: white;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -9px;
    }

    /*curseur custom firefox*/
    input[type=range]::-moz-range-thumb {
        height: 41px;
        width: 41px;
        border-radius: 50px;
        background-image: url('/assets/lamorim/icons/thumbWithArrow.svg');
        cursor: pointer;
        -webkit-appearance: none;
    }

    /*hide thumb*/
    .custom-range input[type=range]::-webkit-slider-thumb {
        visibility: hidden;
    }

    .verticalLinePopUp {
        background-color: white;
        height: 15px;
        width: 2px;
        position: relative;
        left: 23px;
    }
}

/*popin positionnement du text sur la popup*/
.popInContentTimeline {
    position: relative;
}

.imgPopinTimeline {
    position: relative;
}

.txtPopinTimeline {
    position: absolute;
    top: 2%;
    left: 50%;
    right: 2%;
    background-color: rgba(255,255,255,0.85);
    padding: 2%;
    font-weight: bold;
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
    }
}

.timeline-dialog {
    // Attention de bien "scoper" les classes transverses
    .mat-dialog-container {
        background: white;
    }

    .mat-dialog-title {
        color: $default-font-color;
    }

    mat-dialog-container.mat-dialog-container .mat-dialog-title {
        background: white;
    }

}




