@use '@angular/material' as mat;
$not-acquired: rgba(236, 0, 140, 0.5); //#EC008C > opacity 1 #f57fc5
$to-do: rgba(255, 140, 0, 0.5); //#FF8C00 opacity 1 #ffc57f
$so-close: rgba(0, 148, 242, 0.5); //#00BCF2 opacity 1 #7fc9f8
$acquired: rgba(0, 178, 148, 0.5); //#00B294 opacity 1 #7fd8c9

app-multi-graph-view, app-single-graph-view {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    background-color: white;

    //____________________ TAB ______ TAB ______ TAB ___________________ //
    .mat-tab-nav-bar {
        border-bottom: none;

        &.mat-primary .mat-ink-bar {
            background-color: mat.get-color-from-palette($accent, 500);
        }

        .mat-tab-links {
            justify-content: center;

            button {
                text-transform: uppercase;
                color: #15213F;
            }
        }
    }

    // -------------------------   FILTERS   ------------------------------ //

    .filters {
        mat-form-field.mat-form-field {
            padding-right: 15px;
            padding-left: 5px;
            max-width: 42%;
        }
    }

    // ------------------------------------------------------------------ //

    .graph-wrapper {
        position: relative; // tooltip reference
    }

    app-level-graph {
        .graph-level {
            display: flex;
            height: 100%;
            flex-direction: row;
            max-height: 100%;
            flex: 1;
            @include media-breakpoint('lt-md') {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: stretch;
                align-content: stretch;
                overflow: scroll;
            }


            // -------------------- | ---------------------- //
            .graph-level-wrapper {
                flex: 1 1 100%;
                max-width: 55%;
                padding: 48px 20px 0 20px;
                @include media-breakpoint('lt-md') {
                    flex: none;
                    padding-bottom: 20px;
                    width: 100%;
                    max-width: 100%;
                }

                .graph {
                    .graph-wrapper {
                        position: relative; // tooltip reference
                        @include media-breakpoint('lt-md') {
                            width: 100% !important;
                        }
                    }

                    .graph-legend {
                        @include media-breakpoint('lt-md') {
                            margin-left: 5%;
                        }
                    }

                    .chartjs-render-monitor {
                        @include media-breakpoint('lt-md') {
                            width: 100% !important;
                        }
                    }
                }

                .graph-legend {
                    list-style-type: none;
                    padding: 0;
                    margin: -21px 0 0 21px;
                    display: flex;

                    li {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        align-items: center;

                        mat-icon.mat-icon {
                            width: 42px;
                            height: 42px;
                        }

                        span {
                            text-align: center;
                            color: $default-font-color;
                            font-weight: 700;
                        }

                        &.not_acquired {
                            color: $not-acquired;
                        }

                        &.to_do {
                            color: $to-do;
                        }

                        &.so_close {
                            color: $so-close;
                        }

                        &.acquired {
                            color: $acquired;
                        }
                    }
                }
            }

            .details {
                flex: 1;
                max-width: 45%;
                padding: 0 10px;
                @include media-breakpoint('lt-md') {
                    max-width: 100%;
                    min-height: 580px;
                }

                &.column-0 {
                    th, mat-toolbar {
                        background-color: #f57fc5; //rgb(236, 0, 140);
                    }
                }

                &.column-1 {
                    th, mat-toolbar {
                        background-color: #ffc57f;
                    }
                }

                &.column-2 {
                    th, mat-toolbar {
                        background-color: #7fc9f8;
                    }
                }

                &.column-3 {
                    th, mat-toolbar {
                        background-color: #7fd8c9;
                    }
                }

                .mat-toolbar {
                    border-radius: 5px;
                    margin: 0 0 14px 8px;
                    color: white;
                    height: 32px;
                    width: calc(100% - 27px);
                    font-weight: 700;
                }

                .table-container {
                    overflow: scroll;
                    max-height: 55vh;
                    position: relative;
                    box-shadow: 0px 18px 0px 0px rgba(255, 255, 255, 1) inset;
                    -webkit-box-shadow: 0px 18px 0px 0px rgba(255, 255, 255, 1) inset;
                    -moz-box-shadow: 0px 18px 0px 0px rgba(255, 255, 255, 1) inset;

                    table {
                        th.mat-header-cell, td.mat-cell {
                            text-align: center;
                            border: none;
                        }

                        thead {
                            .mat-header-cell {
                                font-weight: 700;
                                top: 6px !important;
                                -webkit-box-shadow: 0px -20px 0px 0px #FFFFFF;
                                box-shadow: 0px -20px 0px 0px #FFFFFF;
                            }
                        }

                        tr,
                        tr.mat-row {
                            box-shadow: none;
                            border-radius: 0;
                            height: 48px;
                            cursor: default;

                            &.mat-header-row {
                                height: 36px;
                            }

                            td:first-child {
                                padding-left: 24px;
                            }

                            td.mat-cell:last-of-type,
                            td.mat-footer-cell:last-of-type,
                            th.mat-header-cell:last-of-type {
                                padding-right: 8px;
                            }
                        }
                    }
                }

            }

        }
    }

    app-errors-graph {

        .no-data-container {
            @extend .center;
            display: block;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            min-height: 100px;
            height: 100px;
            text-align: center;
        }

        app-graph-mixed {
            flex: 1
        }

        .graph-errors {
            display: flex;
            height: 100%;
            flex-direction: row;
            max-height: 100%;
            flex: 1;

            @include media-breakpoint('lt-md') {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: stretch;
                align-content: stretch;
                overflow: scroll;
            }


            // -------------------- | ---------------------- //
            .graph {
                .chartjs-render-monitor {
                    @include media-breakpoint('lt-md') {
                        width: 100% !important;
                    }
                }
            }

            .graph-wrapper {
                position: relative; // tooltip reference
                @include media-breakpoint('lt-md') {
                    width: 100% !important;
                }
            }

            .details {
                flex: 1;
                max-width: 45%;
                padding: 0 10px;
                @include media-breakpoint('lt-md') {
                    max-width: 100%;
                    min-height: 580px;
                }

                .mat-toolbar {
                    border-radius: 5px;
                    margin: 0 0 14px 8px;
                    color: white;
                    height: 32px;
                    width: calc(100% - 27px);
                    font-weight: 700;
                }

                .table-container {
                    overflow: scroll;
                    max-height: 55vh;
                    position: relative;
                    box-shadow: 0px 18px 0px 0px rgba(255, 255, 255, 1) inset;
                    -webkit-box-shadow: 0px 18px 0px 0px rgba(255, 255, 255, 1) inset;
                    -moz-box-shadow: 0px 18px 0px 0px rgba(255, 255, 255, 1) inset;

                    table {
                        th.mat-header-cell, td.mat-cell {
                            text-align: center;
                            border: none;
                        }

                        thead {
                            .mat-header-cell {
                                font-weight: 700;
                                top: 6px !important;
                                -webkit-box-shadow: 0px -20px 0px 0px #FFFFFF;
                                box-shadow: 0px -20px 0px 0px #FFFFFF;
                            }
                        }

                        tr,
                        tr.mat-row {
                            box-shadow: none;
                            border-radius: 0;
                            height: 48px;
                            cursor: default;

                            &.mat-header-row {
                                height: 36px;
                            }

                            td:first-child {
                                padding-left: 24px;
                            }

                            td.mat-cell:last-of-type,
                            td.mat-footer-cell:last-of-type,
                            th.mat-header-cell:last-of-type {
                                padding-right: 8px;
                            }
                        }
                    }
                }

            }

        }
    }

    app-progress-graph, app-own-progress-graph {
        .mat-chip-list-wrapper .mat-standard-chip.challenge-chip {

            &.mat-chip.mat-standard-chip.mat-chip-disabled {
                opacity: 1;
            }

            margin-left: 15px;
            background-color: $primary-c;

            span {
                margin: 0 2px
            }

            span:nth-child(even) {
                font-weight: bold;
            }
        }
    }

    app-attendance-graph {
        mat-spinner {
            margin: auto;
            margin-top: 15%;
        }
    }

    app-statistics-graph {
        height: 100%;

        & > div {
            height: 100%;
        }
    }

    app-fake-stepper {
        .states {
            position: relative;
            max-width: 100%;
            min-height: 150px;
            overflow: hidden;

            .state {
                margin-top: 2px;
                position: absolute;
            }
        }
    }
}
