@use '@angular/material' as mat;
@use "sass:map";

// TODO revoir l'utilisation des classes
.undo-button, .redo-button {
    @include button("tertiary", $text-hide: true);
}

.next-button {
    @include button("tertiary", $icon-on-Right: true);
}
.preview-button {
    @include button("tertiary", $icon-on-Right: true);
}

// TODO revoir l'utilisation des classes
.edit-button {
    @include button( "tertiary", "s", $icon-full: true, $text-hide: true );
}
.undo-button { // TODO revoir l'utilisation des classes
    @include button("tertiary", $group-button-position: "start");
}
.redo-button { // TODO revoir l'utilisation des classes
    @include button("tertiary", $group-button-position: "end");
}

.mat-dialog-title button.close {
    @include button("primary", $text-hide: true, $icon-full: true);
}




// Commun à tous les boutons
%button--base {

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @include mat.elevation(2);

    font-weight: 700;
}

// Ajoute la couleur d'action su theme
@mixin button--accent {
    color: $button--accent-color;
    background-color: $accent-c;
    border: #{$button--accent--border};
    box-shadow: #{$button--accent--box-shadow};
    border-radius: #{$button--accent--border-radius};
    font-weight: #{$button--accent--font-weight};
    font-size: #{$button--accent--size};
    &:hover {
        color: $button--accent-color;
        background: darken(saturate(adjust-hue($accent-c, 3), 1.58), 3.53);
        .mat-icon {
            color: $button--accent-color;
        }
    }
    // @include button("primary");
}
%button--accent {
    @include button--accent;
}

%button--actif {
    color: $button--actif--color;
    background-color: $button--actif--background-color;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

%button--only-icon {
    background-color: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
}

%mat-stroked-button {
    @extend %button--base;
    display: flex;
    align-items: center;
    margin-right: 12px;
    padding: 0 50px;

    font-weight: 700;
    box-sizing: border-box;

    text-decoration-line: none !important;

    text-transform: uppercase;

    > * {
        text-transform: uppercase;
    }
}

%mat-stroked-button--primary {
    @extend %mat-stroked-button;
    border: 1px solid map.get($primary, 500);
    color: $button--primary-color;
    background-color: map.get($mat-white, 500);
}

/*
*    ____
*   | @ |
*   ----
*
 */
%button--square-icon {
    display: flex;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    @include sizeFix(32px);
    @extend %button--base;
    @extend %button--accent;

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;
    }

}

%button--square-icon--actif {
    display: flex;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    @include sizeFix(40px);
    @extend %button--base;
    @extend %button--actif;

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;
    }

}

%button--wide {
    @extend %button--base;
    @extend %button--accent;
    justify-content: flex-start !important;
    gap: 16px;
    width: calc(100% - 48px);
    padding: 16px;
    margin: 0 24px;
    color: $button--accent-color !important;
    border-radius: 10px;
    font-weight: 700;

    .mat-icon {
        width: 24px;
        height: 24px;
        color: $button--accent-color !important;
    }

    &[disabled] {
        color: $button--accent-color;
    }
}

/*
*    ____
*   | X |
*   ----
*
 */
%button__close {
    @extend %button--square-icon;
}


%svgFullContent {
    justify-content: center;
    align-items: center;
    box-shadow: none;

    .mat-button-wrapper {
        height: 100%;
        min-height: 100%;
        width: 100%;
    }

    .mat-icon {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    svg {
        transform: scale(1.15); // remove svg inner margin
    }
}

app-generic-stepper,
.fuse-app-lessons-header {

    button {
        @extend %button--base;
        @extend %button--accent;
        line-height: 36px;
        padding: 0 16px;
    }
}


button, a {

    &.mat-button-base { // présent sur tous les boutons
        @if ($mat-button-base-extend) {
            @extend #{$mat-button-base-extend};
        }
    }

    &.mat-raised-button {
        @if ($mat-raised-button-extend) {
            @extend #{$mat-raised-button-extend};
        }
    }

    &.mat-raised-button.mat-accent {
        @extend %button--accent;
    }

    &.button-image.mat-raised-button,
        // &.button-image.mat-raised-button:not([class*=mat-elevation-z])
    {
        border: none;
        box-shadow: none;
    }

    &.mat-fab, &.mat-mini-fab {
        //width: auto;
        //height: auto;

        .mat-button-wrapper {
            padding: 0;
        }


        &.mat-accent, .mat-button-wrapper {
            mat-icon, .mat-icon {

                &.svg-icon-class_add,
                &.svg-icon-institution_add,
                &.svg-icon-student_add,
                &.svg-icon-trainer_add,
                &.svg-icon-workgroup_add {
                    color: $button-mat-fab-stroke-color;
                    font-size: $button-mat-fab-dimension;
                    width: $button-mat-fab-dimension;
                    height: $button-mat-fab-dimension;
                    min-width: $button-mat-fab-dimension;
                    min-height: $button-mat-fab-dimension;
                    line-height: $button-mat-fab-dimension;
                    transform: scale(1.02);
                }
            }
        }

        &.mat-accent {
            background-color: $accent-c;

            svg path {
                // fill: transparent; // on ne doit pas déçider ici c'est trop général
            }
        }

    }

    &.flag { // boutons avec un état on/off
        .mat-icon {
            color: $button__flag--color;
        }

        .mat-icon path {
            fill: transparent;
        }

        &:hover {
            .mat-icon path {
                fill: $button__flag--hover--color; //contre default
            }
        }

        &.active {
            .mat-icon {
                color: $button__flag--active--color;

                path {
                    fill: $button__flag--active--color;
                }
            }
        }
    }
}

button.mat-accent {
    @extend %button--accent;
}

button + button, .mat-mini-fab + .mat-mini-fab, .mat-raised-button + .mat-raised-button {
    margin-left: 8px;
}

button.mat-menu-trigger {

    @if ($button__mat-menu-trigger--extend) {
        @extend #{$button__mat-menu-trigger--extend};
    }

    .mat-icon {
        color: $button__mat-menu-trigger--color;

        .mat-table & { // quand le bouton menu est dans un tableau
            color: $table__button__icon--color;
        }
    }

}




mat-dialog-container .mat-button-base {
    @if ($mat-button-base-extend) {
        @extend #{$mat-button-base-extend};
    }
}

.btn-webapp.mat-raised-button {
    background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    border-radius: 1.5em;
    margin: 0 40px;
    height: 48px;
    overflow: hidden;
    color: white;
    display: flex;

    .mat-button-wrapper {
        height: 100%;
        padding: 0 30px 0 10px;
        flex: 1;

        img {
            width: 45px;
            margin-right: 15px;
            align-self: flex-end;
        }

        span {
            font-weight: 800;
            white-space: normal;
            display: block;
            width: 100px;
            line-height: 1.5rem;
        }
    }

    &:hover {
        background: rgb(131, 207, 255);
        background: linear-gradient(180deg, rgba(131, 207, 255, 1) 0%, rgba(0, 175, 236, 1) 50%);
    }
}

// generic add button in all page
.mat-fab.mat-fab-bottom-right {
    position: absolute;
    right: 20px;
    left: auto;
    bottom: 20px;
    top: auto;
    z-index: 1002;
}

app-button-help {
    margin-left: auto;
}

%button--basic {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

%button--inverted {
    color: $button-inverted--color;
    background-color: $button-inverted--background-color;
    border: solid 1px $button-inverted--color;

    .mat-icon {
        color: $button-inverted--color;
    }

    &:active, &:hover, &.flag:active, &.flag:hover {
        .mat-icon {
            color: $button-inverted--color;
        }
    }
    &.flag .mat-icon {
        color: $button-inverted--color;
    }


    &.flag.active .mat-icon {
        color: $button-inverted--color;
    }

}

// because the button style is on top of everything it has effect on every button
// remove the extra unexpected behaviour for some element calendar menu expand button etc...
// i can't apply exception like "button:not(.mat-button-table)" because the weight of css selector increase
// and replace some spécific css in place who must stay in place
button.mat-calendar-body-cell,
button.mat-calendar-period-button,
button.mat-calendar-previous-button,
button.mat-calendar-next-button,
button.navbar-toggle-button,
button.toggle-sidebar-folded,
button.toggle-sidebar-opened,
button.user-button,
button.btn__goToLoginPage {
    box-shadow: none;
    border-radius: inherit;
    font-weight: inherit;
    font-size: inherit;
}

button.user-button,
button.btn__goToLoginPage {
    font-weight: 500;
}



/**
    // Working document is waiting to be move to specific graphical charter module

    // **************** DON'T REMOVE THIS ********************

    <div class="demo-button">
        <div class="demo-primary">
            <button class="primary"><mat-icon svgIcon="add"></mat-icon><span>primary</span></button>
            <button class="primary-icon-on-right"><mat-icon svgIcon="add"></mat-icon><span>primary icon right</span></button>
            <button class="primary-icon-full"><mat-icon svgIcon="add"></mat-icon><span>primary icon full</span></button>
            <button class="primary-text-hide"><mat-icon svgIcon="add"></mat-icon><span>primary text-hide</span></button>
            <button class="primary" disabled><mat-icon svgIcon="add"></mat-icon><span>primary disabled</span></button>
        </div>
        <div class="demo-secondary">
            <button class="secondary"><mat-icon svgIcon="add"></mat-icon><span>secondary</span></button>
            <button class="secondary-icon-on-right"><mat-icon svgIcon="add"></mat-icon><span>secondary icon right</span></button>
            <button class="secondary-icon-full"><mat-icon svgIcon="add"></mat-icon><span>secondary icon full</span></button>
            <button class="secondary-text-hide"><mat-icon svgIcon="add"></mat-icon><span>secondary text-hide</span></button>
            <button class="secondary" disabled><mat-icon svgIcon="add"></mat-icon><span>secondary disabled</span></button>
        </div>
        <div class="demo-tertiary">
            <button class="tertiary"><mat-icon svgIcon="add"></mat-icon><span>tertiary</span></button>
            <button class="tertiary-icon-on-right"><mat-icon svgIcon="add"></mat-icon><span>tertiary icon right</span></button>
            <button class="tertiary-icon-full"><mat-icon svgIcon="add"></mat-icon><span>tertiary icon full</span></button>
            <button class="tertiary-text-hide"><mat-icon svgIcon="add"></mat-icon><span>tertiary text-hide</span></button>
            <button class="tertiary" disabled><mat-icon svgIcon="add"></mat-icon><span>tertiary disabled</span></button>

        </div>
        <div class="demo-tertiary">
            <button class="tertiary group-start" ><mat-icon svgIcon="add"></mat-icon><span>tertiary group</span></button>
            <button class="tertiary group-middle" ><mat-icon svgIcon="add"></mat-icon><span>tertiary group</span></button>
            <button class="tertiary group-end" ><mat-icon svgIcon="add"></mat-icon><span>tertiary group</span></button>
        </div>
        <div class="demo-tertiary">
            <button class="tertiary group-start text-hide" ><mat-icon svgIcon="add"></mat-icon><span>tertiary group</span></button>
            <button class="tertiary group-middle text-hide" ><mat-icon svgIcon="add"></mat-icon><span>tertiary group</span></button>
            <button class="tertiary group-end text-hide" ><mat-icon svgIcon="add"></mat-icon><span>tertiary group</span></button>
        </div>
        <div class="demo-dark">
            <button class="dark"><mat-icon svgIcon="add"></mat-icon><span>dark</span></button>
            <button class="dark-icon-on-right"><mat-icon svgIcon="add"></mat-icon><span>dark icon right</span></button>
            <button class="dark-icon-full"><mat-icon svgIcon="add"></mat-icon><span>dark icon full</span></button>
            <button class="dark-text-hide"><mat-icon svgIcon="add"></mat-icon><span>dark text-hide</span></button>
            <button class="dark" disabled><mat-icon svgIcon="add"></mat-icon><span>tertiary disabled</span></button>
        </div>
        <div class="demo-secondary">
            <button class="secondary-text-hide"><mat-icon svgIcon="trash"></mat-icon><span>dark icon right</span></button>
            <button class="secondary-group"><mat-icon svgIcon="move_up"></mat-icon><span>dark icon right</span></button>
            <button class="secondary-group"><mat-icon svgIcon="move_down"></mat-icon><span>dark icon right</span></button>
        </div>
    </div>

    // **************** CSS

        .demo-button {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 50vw;
        height: auto;
        padding: 20px;
        background-color: lightblue;
        flex-wrap: wrap;
        gap: 12px;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        .demo-primary, .demo-secondary, .demo-tertiary, .demo-dark {
            display: flex;
            padding: 5px;
            border: 1px solid #fff;
        }
        .demo-dark { background-color: #323A49; }
    }

    .primary { @include button("primary", "m"); }
    .primary-icon-on-right { @include button("primary", "m", true); }
    .primary-icon-full { @include button("primary", "m", false, true); }
    .primary-text-hide { @include button("primary", "m", false, false, $text-hide: true); }

    .secondary { @include button("secondary", "m"); }
    .secondary-icon-on-right { @include button("secondary", "m", true); }
    .secondary-icon-full { @include button("secondary", "m", false, true); }
    .secondary-text-hide { @include button("secondary", "m", false, false, $text-hide: true ); }

    .tertiary { @include button("tertiary", "m"); }
    .tertiary-icon-on-right { @include button("tertiary", "m", true); }
    .tertiary-icon-full { @include button("tertiary", "m", false, true); }
    .tertiary-text-hide { @include button("secondary", "m", false, false, $text-hide: true ); }
    .tertiary.group-start { // TODO revoir l'utilisation des classes
        @include button("tertiary", $group-button-position: "start");
    }
    .tertiary.group-middle { // TODO revoir l'utilisation des classes
        @include button("tertiary", $group-button-position: "middle");
    }
    .tertiary.group-end { // TODO revoir l'utilisation des classes
        @include button("tertiary", $group-button-position: "end");
    }
    .tertiary.group-start.text-hide { // TODO revoir l'utilisation des classes
        @include button("tertiary", $group-button-position: "start", $text-hide: true);
    }
    .tertiary.group-middle.text-hide { // TODO revoir l'utilisation des classes
        @include button("tertiary", $group-button-position: "middle", $text-hide: true);
    }
    .tertiary.group-end.text-hide { // TODO revoir l'utilisation des classes
        @include button("tertiary", $group-button-position: "end", $text-hide: true);
    }

    .dark { @include button("dark", "m"); }
    .dark-icon-on-right { @include button("dark", "m", true); }
    .dark-icon-full { @include button("dark", "m", false, true); }
    .dark-text-hide { @include button("dark", "m", false, false, true); }

    .secondary-group { @include button("secondary", "m", false, false, true); }

 */