 .badge {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: center flex-start;
  align-items: center;

  margin-right: 8px;
  padding: 4px 8px;
  background-color: rgba(0,0,0,.4);
  color: #fff;

  border-radius: 14px;

  .to-correct {
    background-color: red;
  }

  .closed {
    background-color: red;
  }
}
