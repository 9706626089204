// Stylesheet already nested inside ".activities-card" class (see @import rules in "@modules/activities/scss/main.scss" and "@modules/activities/scss/main-plateforme.scss")

button {
    @include secondary-button;
    margin-inline: 0; // problème liée à un global button + button
}

mat-card-footer {

    button {
        @include g-4;
    }
}