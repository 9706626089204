@use '@angular/material' as mat;

%mat-stroked-button {
    border-radius: 2em;
    font-weight: 700;
    box-sizing: border-box;
    margin-right: 12px;
    padding: 0 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @include mat.elevation(1);

    text-decoration-line: none !important;
    display: flex;
    align-items: center;

    text-transform: uppercase;

    > * {
        text-transform: uppercase;
    }
}

%mat-stroked-button-primary {
    @extend %mat-stroked-button;
    border: 1px solid map-get($primary, 500);
    color: map-get($primary, 500);
    background-color: map-get($mat-white, 500);
}
%mat-stroked-button-accent {
    border: $button--accent--border;
    color: var(--typo);
    background-color: $accent-c;
}

%svgFullContent {
    justify-content: center;
    align-items: center;
    box-shadow: none;

    .mat-button-wrapper {
        height: 100%;
        min-height: 100%;
        width: 100%;
    }

    .mat-icon {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    svg {
        transform: scale(1.15); // remove svg inner margin
    }
}

button {
    cursor: pointer;

    &[disabled] {
        background-color: #CBD1DB !important;
        color: #949DA3 !important;
        pointer-events: none;
    }
}

button, a {

    &.mat-raised-button,
    &.mat-raised-button.mat-accent,
    &.mat-raised-button.accent {
        border-radius: 2em;
        font-weight: 700;
        @extend %mat-stroked-button-accent;
    }

    &.mat-fab,
    &.mat-mini-fab {
        width: auto;
        height: auto;

        &.mat-accent,
        .mat-button-wrapper {

            mat-icon,
            .mat-icon {

                &.svg-icon-class_add,
                &.svg-icon-institution_add,
                &.svg-icon-student_add,
                &.svg-icon-trainer_add,
                &.svg-icon-workgroup_add {
                    color: $button-mat-fab-stroke-color;
                    font-size: $button-mat-fab-dimension;
                    width: $button-mat-fab-dimension;
                    height: $button-mat-fab-dimension;
                    min-width: $button-mat-fab-dimension;
                    min-height: $button-mat-fab-dimension;
                    line-height: $button-mat-fab-dimension;
                    transform: scale(1.02);
                }
            }
        }

        &.mat-accent {
            background-color: $button-mat-fab-background-color;

            svg path {
                fill: transparent;
            }
        }
    }
}

button.tooltip-info {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    box-shadow: none !important;

    &.mat-flat-button, &.mat-raised-button, &.mat-fab, &.mat-mini-fab {
        background-color: #F8F8F8;
    }

}

.btn-webapp.mat-raised-button {
    background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    border-radius: 1.5em;
    margin: 0 40px;
    height: 48px;
    overflow: hidden;
    color: white;
    display: flex;

    .mat-button-wrapper {
        height: 100%;
        padding: 0 30px 0 10px;
        flex: 1;

        img {
            width: 45px;
            margin-right: 15px;
            align-self: flex-end;
        }

        span {
            font-weight: 800;
            white-space: normal;
            display: block;
            width: 100px;
            line-height: 1.5rem;
        }
    }

    &:hover {
        background: rgb(131, 207, 255);
        background: linear-gradient(180deg, rgba(131, 207, 255, 1) 0%, rgba(0, 175, 236, 1) 50%);
    }
}
// base of basic button
%main-button {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    min-height: 40px;
    min-width: 230px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box !important;
    box-shadow: 0 1px 0 map_get($accent, 900), 0 2px 2px rgba(0, 0, 0, 0.1) !important;
    border-radius: 3px !important;
    @extend %mat-stroked-button-accent;

    mat-icon {
        min-width: 20px !important;
        min-height: 20px !important;
        margin-left: 17px;
    }

    span.mat-button-wrapper {
        color: white;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

%yellow-button {
    @extend %main-button;
    background: #FCB731 !important;
}

%red-button {
    @extend %main-button;
    background: #FF6862 !important;
}