@use 'node_modules/@angular/material/index' as mat;
/*
fuse-app-lessons > #lesson >
    app-stepper-wrapper ___________________________________________________
        > div [top|bottom|right|left] _____________________________________ //TODO utiliser classe rm fxLayout
        |                                                               |
        |                     app-generic-stepper                       |
        |        .app-stepper-wrapper__side-generic-stepper           | // voir ng-container lesson.component
        |                                                               |
    _______________________________________________________________________
*/

/* ||   GENERAL STYLES  */


/* ||   LAYOUT  */

app-stepper-wrapper {
    flex: 1;
    display: flex;
    overflow: auto;

    > div.page-layout { // [fxLayout]="layout()" > style="..."


        // remplace fxFlex (deprecated)
        &.stepper--top {
            flex-direction: column;
        }
        &.stepper--bottom {
            flex-direction: column-reverse;
        }
        &.stepper--right {
            flex-direction: row-reverse;
        }
        &.stepper--left {
            flex-direction: row;
        }
    }
}

// app-generic-stepper

.app-stepper-wrapper__side-generic-stepper {
    flex: 1;
    display: flex;
    // padding: 0 var(--padding-lateral-viewport); // non pas de padding global les espaces sont géré par les composants suivant leurs emplacement
    // gap: var(--gap-vertical-viewport);

    overflow: auto;

    .fuse-app-lessons-header {
        // voir _fuse-app-lessons-header
    }

    &__content { // app-stepper-wrapper__side-generic-stepper__content
        flex: 1;
        display: flex;
        gap: var(--gap-vertical-viewport);

        /*
        .app-stepper-wrapper__side-generic-stepper__content
            .outlet-player-navigation _____________________________________________
            |                 |                                     |             |
            |                 |                                     |             |
            |.previous-wrapper|            app-multi                |.next-wrapper|
            |                 |                                     |             |
            |                 |                                     |             |
            _______________________________________________________________________
        */

        .outlet-player-navigation {
            display: flex;
            // flex: 1 0 auto;
            flex: 1;
            width: 100%;
            // height: 100%; // avec la progression élève le container sors et le bouton suivant avec
            overflow: auto;

            > .previous-wrapper, > .next-wrapper {
                min-width: 60px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                align-content: stretch;
                flex: none;

                display: none !important; // TODO Doit être un setting

                .previous, .next {
                    padding: 0;
                    width: 32px;
                    height: 32px;
                }
            }

            app-multi {
                // voir _app-multi
            }
        }
    }
}








#lessons app-stepper-wrapper > .page-layout {}


/**
*                   CENTER.center
 */
/*
#lessons app-stepper-wrapper > .page-layout > .center > content {
    flex: 1 0 auto;
    flex-direction: column;
    display: flex;
    width: 100%;
}
#lessons app-stepper-wrapper > .page-layout > .center {
    height: 100%; // conf stepper null
    margin-top: 1%;
    margin-bottom: 1%;
    width: 96%;
    margin-left: 2%;
    max-width: 96%;

    margin: 0;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
}
#lessons app-stepper-wrapper > .page-layout > app-generic-stepper + .center {
    height: calc(100% - 55px);
}
*/





