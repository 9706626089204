@use '@angular/material' as mat;
$base-font-size: 14px;

//@font-face {
//  font-family: 'MikadoBold';
//  font-weight: bold;
//  src: local('mikadobold'), local('mikadobold'),
//  font('mikadobold-webfont.woff2') format('woff2'),
//  font('mikadobold-webfont.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'nexa';
//  src: font('Nexa_Free_Light.otf') format("opentype");
//}
//
//@font-face {
//  font-family: 'nexabold';
//  font-weight: bold;
//  src: font("Nexa_Bold.otf") format("opentype");
//}

body {
  font-size: $base-font-size;
}

//$general-typography: mat-typography-config(
//        $font-family: '"nexabold", "Helvetica Neue", Arial, sans-serif'
//);
//
//$general-typography: mat-typography-config(
//        $body-2:        mat-typography-level(14px, 24px, 600)
//);
//
//$secondary-typography: mat-typography-config(
//        $font-family: 'nexabold'
//);
//
//
//$custom-typography: mat-typography-config(
//        $font-family: 'nexabold',
//        $title: mat-typography-level(34px, 24px, 900, 'MikadoBold')
////,
//       //$body-2: mat-typography-level(132px, 148px, 700)
//);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
//@include mat-base-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
//@include mat-base-typography($custom-typography);

 .lead, .lead p {
  font-size: 18px;
}
 .text-info, .text-info p {
   color: mat.get-color-from-palette(mat.$blue-palette, 500);
 }
 .text-success, .text-success p {
  color: mat.get-color-from-palette(mat.$green-palette, 500);
}
 .text-danger, .text-danger p {
  color: mat.get-color-from-palette(mat.$red-palette, 500);
}
