mat-expansion-panel {
    &.mat-expansion-panel {
        .mat-expansion-panel-header {
            .mat-icon {
                display: none;
                &[svgicon="expand"] path {
                    fill: none;
                }
            }
        }
    }
}
