@use '@angular/material' as mat;

// --------------------------------------------------
// SPACING (fluid scale calculator: https://www.fluid-type-scale.com)
// --------------------------------------------------

$spacing-0: 0;
$spacing-1: clamp(5px, 0.38vw + 2.73px, 10px); // 5px to 10px
$spacing-2: clamp(10px, 0.76vw + 5.45px, 20px); // 10px to 20px
$spacing-3: clamp(20px, 1.52vw + 10.91px, 40px); // 20px to 40px
$spacing-4: clamp(40px, 3.03vw + 21.82px, 80px); // 40px to 80px
$spacing-5: clamp(80px, 6.06vw + 43.64px, 160px); // 80px to 160px
$spacing-auto: auto;
$spacing-2neg: clamp(-10px, 0.76vw + 5.45px, -20px); // -10px to -20px

// --------------------------------------------------
// RADIUS
// --------------------------------------------------

$radius-0: 0;
$radius-1: clamp(8px, 0.38vw + 2.73px, 12px); // 8px to 12px
$radius-2: clamp(12px, 0.76vw + 5.45px, 26px); // 12px to 26px
$radius-3: clamp(16px, 1.52vw + 10.91px, 24px); // 16px to 24px
$radius-4: clamp(24px, 3.03vw + 21.82px, 32px); // 24px to 32px
$radius-5: clamp(32px, 6.06vw + 43.64px, 64px); // 32px to 64px
$radius-rounded: 50%;

// Figma semantic matching
:root {
    --radius-s: #{$radius-1};
    --radius-m: #{$radius-2};
    --radius-l: #{$radius-3};
    --radius-xl: #{$radius-4};
    --radius-xxl: #{$radius-5};
}

// --------------------------------------------------
// COLORS
// --------------------------------------------------

// Figma colors
:root {
    --hightlight: #ACE5E0;
    --action_interaction: #E1FF95;
    --typo: #35301E;
    --blanc: #FFFFFF;
    --faux_blanc: #F9FBFF;
    --gris_de_contour: #CBD1DB;
    --noir_5: rgba(0, 0, 0, 0.05);
    --noir_10: rgba(0, 0, 0, 0.1);
    --fond_ecran: #E8EDF6;
    --vert: #00FF94;
    --bleu_claire: #ACE5E0;
    --bleu_fonce: #007E93;
    --rose: #FFC4B5;
    --violet: #AFB3E4;
    --orange: #FF7A00;
}

// Main color aliases
$primary-c: var(--bleu_fonce);
$secondary-c: map_get($mat-fusedark, 500);
$quaternary-c: var(--bleu_fonce);
$accent-c: #E1FF95;
$black-c: map_get($mat-black, 500);
$white-c: map_get($mat-white, 500);

// --------------------------------------------------
// TYPOGRAPHY
// --------------------------------------------------

$root-em: 10px; // Root element font-size
$font-family: '"Roboto", "Helvetica Neue", Arial, sans-serif';
$default-font: '500 16px/24px "Roboto", "Helvetica Neue", sans-serif';
$default-font-size: 14px;
$default-font-color: map_get($mat-fusedark, 900);
$default-font-contrast-color: map_get($mat-white, 500);

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

// ========== LOGO ==========

[svgicon="brand"] { // sidebar
    .brand-background {
        fill: map_get($mat-fusedark, 500); //primary
    }
}
[svgicon="brand_square"] { // login
    .brand-text-ia {
        color: map_get($mat-fusedark, 500); //primary
    }
    .brand-background {
        fill: map_get($mat-white, 500); //primary
    }
}

// ========== ICONS ==========

$mat-icon-default-color: map_get($mat-black, 500);
$mat-icon-default-contrast-color: map_get($mat-white, 500);

// ========== SCROLLBAR ==========

$perfectscrollbar-default-background: mat.get-color-from-palette($accent);
$perfectscrollbar-hover-background: $perfectscrollbar-default-background;
$perfectscrollbar-hover-width: 11px;

// ========== BUTTON ==========

$button--accent-color: var(--typo,);
$button--primary-color: white;

$button-inverted--color: $accent-c;
$button-inverted--background-color: var(--blanc);

$button--accent--border: 1px solid rgba(0, 0, 0, 0.1);
$button--accent--box-shadow: '0px 4px 10px rgba(0, 0, 0, 0.25)';
$button--accent--border-radius: '4px';
$button--accent--font-weight: '500';
$button--accent--size: $default-font-size;

$button--actif--color: $default-font-contrast-color;
$button--actif--background-color: $secondary-c;

$mat-button-base-extend: null;
$mat-raised-button-extend: '%button--accent';

$button-mat-fab-background-color: inherit;
$button-mat-fab-dimension: 65px;
$button-mat-fab-stroke-color: map_get($accent, 500);

$button__flag--color: var(--blanc);
$button__flag--hover--color: transparent;
$button__flag--active--color: var(--blanc); // white

$button__mat-menu-trigger--extend: null; // null ou Nom Placholder
$button__mat-menu-trigger--color: var(--blanc); // white

// ========== FORM FIELDS ==========

$form-field-label-font-size: '14px';
$form-field-input-color: $default-font-color;
$form-field-input-label-color: $default-font-color;
$form-field-input-label-color-focused: $default-font-contrast-color;
$default-input-height: 30px;
$form-field-input-font: 500 12px/36px "Roboto", sans-serif;
$default-input-line-height: $default-input-height;
$form-field-label-font: 500 #{$form-field-label-font-size}/16px "Roboto", sans-serif;
$form-field-label-text-transform: "uppercase";
$form-field-label-top: 0;
$form-field-background-color: rgba(0, 0, 0, 0.12);
$form-field-underline-background-color: rgba(0, 0, 0, 0.4);
$form-field-underline-bottom: 10px;

$form-field-flex-border: 1px solid $default-font-color;
$form-field-flex-border-top: none;
$form-field-flex-border-bottom: none;
$form-field-flex-border-left: none;
$form-field-flex-border-right: none;
$form-field-ripple-background: mat.get-color-from-palette($accent);
$form-field-infix-padding: '0 5px 5px 0';
$form-field-infix-line-height: 2em;
$form-field-margin-bottom: 0.5em;
$form-field-select-panel-background-color: map_get($mat-white, 500);

$mat-form-field-checkbox-color: rgba(0, 0, 0, 0.5);
$mat-form-field-checkbox-background-color: map_get($mat-white, 500);
$mat-form-field-checkbox-border-radius: 4px;

$mat-form-field-checkbox-color-checked: mat.get-color-from-palette($accent);;
$mat-form-field-checkbox-background-color-checked: mat.get-color-from-palette($accent);

$mat-form-field-option-color: rgba(0, 0, 0, 0.5);

// ========== NAVBAR SIDEBAR ==========

/* *** navbar-header *** */
// Couleur de fond
$navbar-content-background-color: #00AFCB;
$navbar-header-background-color: 'transparent';
$navbar-header-box-shadow: 'none';
$navbar-header-icon-dimensions: 32px;
$navbar-header-icon-svg-color: map_get($mat-white, 500);
// Dimension du logo
$logo-icon-svg-color: mat.get-color-from-palette($mat-white, 500);
$logo-icon-svg-width: 142px;
$logo-icon-svg-height: 78px;

// Couleurs des élements textuels et icones
$nav-font-color: $default-font-contrast-color;
$nav-link-title-font-size: 1.6rem;
$nav-link-title-color: map_get($mat-white, 500);
$nav-link-title-color-active: map_get($mat-white, 500);
$nav-icon-color: map_get($mat-white, 500);

$nav-link-icon-dimension: 26px;
$nav-link-icon-font-size: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-line-height: $nav-link-icon-dimension;
$nav-link-icon-color-active: map_get($mat-white, 500);
$nav-link-icon-color-hover: $nav-link-icon-color-active;

// ========== TABLE ==========

$table-thead-background-color: var(--bleu_fonce);
$table-thead-font-color: $default-font-contrast-color;
$table-thead-checkbox-color: $default-font-contrast-color;
$table-thead-border: 1px solid rgba(map_get($primary, 500), 0.5);
$table-thead-border-radius: 5px;

$table-icon-stroke-color: map_get($accent, 500);
$table-icon-stroke-color-hover: map_get($accent, 300);
$table-icon-stroke-inactive-color: #C4C4C4;

$table__button__icon--color: white;

// ========== SUMMARY ==========

$summary--background-image: image('backgrounds/summary-butterflies.png');
$summary--background-size: 15%;
$summary--background-position: bottom 4rem right 10rem;
$summary--background-repeat: no-repeat;
$summary__footer--background-color: unset;

// --------------------------------------------------
// MODULES
// --------------------------------------------------

// ========== ACTIVITIES ==========

$activities__button-selected--background-color: #{var(--bleu_tralalere_accessible)};

/* ** Generic stepper ** */
$stepper__button--color: $default-font-color;
$stepper__button--background-color: var(--blanc);
$stepper__button--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-hover--color: var(--blanc);
$stepper__button-hover--background-color: var(--bleu_tralalere_accessible);
$stepper__button-hover--box-shadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-active--color: $default-font-contrast-color; // var(--bje-jaune);
$stepper__button-active--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-current--color: $default-font-contrast-color;
$stepper__button-current--background-color: $accent-c; // var(--bje-jaune);
$stepper__button-current--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__mat-select-panel--background: 'rgba(50, 58, 73, 0.9)';
$stepper__mat-select-panel--border: 'thin solid #5e5e5e;';
$stepper__mat-select-panel--color: $default-font-contrast-color;
$stepper__mat-select-panel__span--color: #2BBFFF;
$stepper__mat-select--border: '1.5px solid #FFFFFF';
$stepper__mat-select_span--color: white;
$stepper__mat-select-panel__generic_stepper_mat-select-panel--background-color: #323A49;
$stepper__mat-select-panel__generic_stepper_mat-select-panel__hover--background-color: 'rgba(0, 0, 0, 0.04)';
$stepper__mat-select-panel--box-shadow: '0 14px 10px rgba(0, 0, 0, 0.25)';

$stepper__button--padding: 4px 4px;
$stepper__button-disabled--color: var(--blanc);
$stepper__button-disabled--background-color:  $accent-c;

// ========== AUTHENTICATION ==========

//fuse-login and register
$fuse-authentication-padding: '32px 32px 68px 32px ';

$fuse-authentication-color: $default-font-contrast-color;
$fuse-authentication-field-input-font: 500 14px/32px "Roboto", sans-serif;
$fuse-authentication-field-input-color: $default-font-color;
$form-field-label-height: 1em;
$fuse-authentication-border-radius: 20px;
$fuse-authentication-field-label-color: $default-font-contrast-color;
$fuse-authentication-field-infix-line-height: 32px;//'2em';
$fuse-authentication-field-infix-padding: '0 15px 0 15px';
$fuse-authentication-field-infix-border-radius: 5px;
$fuse-authentication-background: #00AFCB;

$fuse-authentication-link-decoration: "underline";
$fuse-authentication-title-font: 500 24px/28px "Roboto", sans-serif;
$fuse-authentication-title-letter-spacing: "0.25px";

$fuse-authentication-button-width: 'auto';
$fuse-authentication-button-border-radius: '1.5em';
$fuse-authentication-button-margin: '0';
$fuse-authentication-button-padding: '14px 16px 11px 16px';
$fuse-authentication-button-radius: '1.5em';
$fuse-authentication-button-background: map-get($mat-white, 500);
$fuse-authentication-button-color: $default-font-color;
$fuse-authentication-button-font: 700 14px/16px "Roboto", sans-serif;
$fuse-authentication-button-text-transform: 'uppercase';

// login-form
$fuse-authentication-login-width: 483px;
$fuse-authentication-logo-width: 274px;
$fuse-authentication-logo-margin: '16px auto 0 auto';
$fuse-authentication-title-margin: '60px 0 40px 0';