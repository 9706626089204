@use '@angular/material' as mat;

$gap: 20px;
$content-widget-padding: 10px;

dashboard {

    .content {
        padding: 32px calc(32px - #{$gap}) 32px 32px;
        min-width: 0;
        height: 100%;
    }

    .filters {
        margin-left: 0;
    }

    .iconEmptyWidget {
        svg {
            path {
                fill: mat.get-color-from-palette($accent);
            }
        }

        width: 150px;
        height: 150px;
        font-size: 150px;
    }

    app-dashwidget-message {
        .mat-column-viewed {
            text-align: center;

            .pastille {
                width: 15px;
                height: 15px;
                background-color: mat.get-color-from-palette(mat.$grey-palette, 500);

                &.active {
                    background-color: mat.get-color-from-palette($accent);
                }
            }
        }
    }

    mat-expansion-panel-header {
        background-color: mat.get-color-from-palette($primary);
        color: white;

        .mat-expansion-panel-header-title {
            color: white;
        }
    }

    .mat-paginator {
        position: fixed;
        right: 0;
        bottom: 0;
    }

}
