@import "../variables";

.navbar-fuse-sidebar {
    width: 255px;
    min-width: 255px;
    max-width: 255px;

    background: #00AFCB;
}

navbar {
    
    &.navbar-background-color {
        background: image('backgrounds/bg_sidenave.jpg') top left / cover no-repeat;
    }

    navbar-vertical-style-2 {

        div.navbar-header {
            gap: 3rem;
            height: unset;
            min-height: 9rem;
            padding: 1rem 2rem 2rem 2rem !important;
            background-image: image('backgrounds/ripped_paper_sidebar.png');
            background-size: 100%;
            background-position: 0 0;
            background-repeat: no-repeat;
            box-shadow: none;

            .folded:not(.unfolded) & {
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                min-height: unset;
                padding: 0 0.5rem 1rem 0.5rem !important;
            }

            .logo {
                flex: auto;

                .link {
                    flex: auto;

                    .mat-icon {
                        width: unset;
                        min-width: unset;
                        height: unset;
                        min-height: unset;
                    }
                }
            }

            .toggle-sidebar-folded,
            .toggle-sidebar-opened {
                flex: none;
                width: unset;
                min-width: unset;
                height: unset;
                padding: unset;
                color: var(--orange);
                transition: color 150ms ease-in-out;

                .folded:not(.unfolded) & {
                    color: white;
                }

                .mat-button-wrapper {

                    .mat-icon {
                        width: auto;
                        min-width: unset;
                        height: unset;
                        min-height: unset;

                        svg {
                            width: 2.6rem;
                            min-width: unset;
                            height: auto;
                            min-height: unset;
                            aspect-ratio: 1;
                        }
                    }
                }
            }
        }
    }
}

// fuse-sidebar.navbar
//      navbar.vertical-style-2
//          navbar-vertical-style-2
//              div.navbar-header
//              div.navbar-content
//                  fuse-navigation
//                      div.nav.vertical
//                          div.navbar-content
//
//                              fuse-nav-vertical-item.wrapper-menu-item-<item.id>.nav-item
//                                  a.nav-link (.active)
//                                  mat-icon.mat-icon.nav-link-icon
//                                  span.nav-link-title
//
//                              fuse-nav-vertical-item.wrapper-menu-collapse-<item.id>.nav-item
//
//                              fuse-nav-vertical-item.wrapper-menu-group-<item.id>.nav-item

// overide bad html class material
navbar navbar-vertical-style-2 .mat-accent-bg {
    background-color: transparent !important; // important => material theme
}

navbar navbar-vertical-style-2 {
    // div.navbar-header
    div.navbar-content {
        // // fuse-navigation
        // // div.nav.vertical
        // // // div.navbar-content
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;

        fuse-nav-vertical-item.nav-item,
        fuse-nav-vertical-collapsable.nav-item {

            // a.nav-link (.active) ------
            .nav-link {
                font-weight: 700;
                padding: 0 20px;
                border-radius: 6px 0 0 6px;
                height: 60px;
                color: var(--blanc);

                // mat-icon.mat-icon.nav-link-icon
                .mat-icon.nav-link-icon,
                .collapsable-arrow {
                    color: var(--blanc);
                    font-size: $nav-link-icon-font-size;
                    width: $nav-link-icon-width;
                    height: $nav-link-icon-height;
                    min-width: $nav-link-icon-width;
                    min-height: $nav-link-icon-height;
                    line-height: $nav-link-icon-line-height;
                    opacity: 1;
                }

                span.nav-link-title {
                    color: var(--blanc);
                    font-size: $nav-link-title-font-size;
                }

                &.active {
                    position: relative;
                    overflow: visible;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        width: 255px;
                        height: 100%;
                        background: transparent image('backgrounds/selection_menu.png') no-repeat 0 -26px;
                        z-index: -1;
                    }

                    .nav-link-title {
                        color: var(--typo);
                    }

                    .nav-link-icon,
                    .collapsable-arrow {
                        color: var(--typo);
                    }
                }

                &:hover:not(.active) {
                    .nav-link-title {
                        color: map_get($md-accent, 500);
                    }

                    .nav-link-icon,
                    .collapsable-arrow {
                        color: map_get($md-accent, 500);
                    }
                }

            }

            .children {
                padding-bottom: 50px;
            }

            &.wrapper-menu-collapsable-parameter {
                margin-bottom: auto;
            }

            &.wrapper-menu-item-mentions-legales {
                margin-top: auto;
            }

            &.wrapper-menu-item-mentions-legales,
            &.wrapper-menu-item-cgurgpd,
            &.wrapper-menu-item-politique-confidentialite {
                .mat-icon.nav-link-icon {
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    min-height: 18px;
                    line-height: 18px;
                    svg {
                        width: inherit;
                        height: inherit;
                        min-height: inherit;
                        min-width: inherit;
                    }
                }

                span.nav-link-title {
                    font-size: 1rem;
                }

                .nav-link {
                    &::before{
                        background: none;
                        display: none;
                    }
                    height: 24px;
                }

            }


        }

        > fuse-nav-vertical-item.nav-item {
            width: 100%;
        }

    }

    .menu-link-block {
        position: relative;
    }

}