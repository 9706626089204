@use '@angular/material' as mat;
@import '@angular/material/theming';

/* http://mcg.mbitson.com/#!?rose=%23ee0072&blue=%2300afec&orange=%23fcb731&gray=%23939598&bluedark=%23004157&themename=mathia#%2F */


$mat-white: (
    500: white,
    contrast: (
        500: $black-87-opacity
    )
);

$mat-black: (
    500: black,
    contrast: (
        500: white,
    )
);

$md-gray: (
    50 : #f2f2f3,
    100 : #dfdfe0,
    200 : #c9cacc,
    300 : #b3b5b7,
    400 : #a3a5a7,
    500 : #939598,
    600 : #8b8d90,
    700 : #808285,
    800 : #76787b,
    900 : #64676a,
    A100 : #e3f0fc,
    A200 : #b4d6f8,
    A400 : #7abdff,
    A700 : #61b0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


/* For use in src/lib/core/theming/_palette.scss */
$bleu_fonce: (
    50 : #e0f0f2,
    100 : #b3d8df,
    200 : #80bfc9,
    300 : #4da5b3,
    400 : #2691a3,
    500 : #007e93,
    600 : #00768b,
    700 : #006b80,
    800 : #006176,
    900 : #004e64,
    A100 : #94e3ff,
    A200 : #61d6ff,
    A400 : #2ec8ff,
    A700 : #14c2ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$action_interaction: (
    50 : #fbfff2,
    100 : #f6ffdf,
    200 : #f0ffca,
    300 : #eaffb5,
    400 : #e6ffa5,
    500 : #e1ff95,
    600 : #ddff8d,
    700 : #d9ff82,
    800 : #d5ff78,
    900 : #cdff67,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #fafff0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-red: (
    50 : #fce5e3,
    100 : #f8beb9,
    200 : #f3938b,
    300 : #ee685c,
    400 : #ea4739,
    500 : #e62716,
    600 : #e32313,
    700 : #df1d10,
    800 : #db170c,
    900 : #d50e06,
    A100 : #fffdfd,
    A200 : #ffcbca,
    A400 : #ff9997,
    A700 : #ff807e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$yellow: (
    50 : #fefff5,
    100 : #fdffe5,
    200 : #fcffd4,
    300 : #faffc2,
    400 : #f9ffb5,
    500 : #f8ffa8,
    600 : #f7ffa0,
    700 : #f6ff97,
    800 : #f5ff8d,
    900 : #f3ff7d,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-fusedark: mat.define-palette($bleu_fonce, 500);
$primary-palette: $bleu_fonce;
$quaternary: $bleu_fonce;
$md-accent: $action_interaction;

// Define the Material palettes
$primary: mat.define-palette($bleu_fonce, 500);
$accent: mat.define-palette($action_interaction, 500);
$warn: mat.define-palette($md-red, 500);
$warn-alt: mat.define-palette($yellow, 500);

// Force the input field font sizes to 16px
$typography: mat.define-legacy-typography-config(
    $font-family: '"Roboto", "Helvetica Neue", Arial, sans-serif',
    $title: mat.define-typography-level(32px, 24px, 500, 'Roboto'),
    $input: mat.define-typography-level(
        $font-size: 1.4rem,
        $font-weight: 400,
        $letter-spacing: normal,
        $line-height: 1.125,
    ),
    $button: mat.define-typography-level(
        $font-size: 1.6rem,
        $font-weight: 700,
        $letter-spacing: normal,
        $line-height: 1.2,
    ),
);

// Create the Material theme object
$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    ),
    typography: $typography,
    density: 0,
));

// Store the background and foreground colors for easier access
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);