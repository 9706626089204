// --------------------------------------------------
// BASE PATH
// --------------------------------------------------

$asset-base-path: '/assets/sapiens' !default;

// --------------------------------------------------
// FUSE
// --------------------------------------------------

@import "../shared_theme/fuse";

// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

@import "variables";
@import "variables-buttons-tabs";
@import "variables-activities-colors";

// --------------------------------------------------
// ABSTRACTS
// --------------------------------------------------

@import "src/app/@modules/css-library/scss/abstracts";

// --------------------------------------------------
// DEFAULT THEME
// --------------------------------------------------

// Variables
@import "../default/variables";

// Helpers
@import "../default/helper";

// Core
@import "../default/core/core";

// Modules
@import "../default/modules/account-management";
@import "../default/modules/achievement";
@import "../default/modules/authentication";
@import "../default/modules/research-sheet";
@import "../default/modules/assignation";
@import "../default/modules/groups-management";
@import "../default/modules/ideas-wall";
@import "../default/modules/licensing";
@import "../default/modules/project-management";
@import "../default/modules/dashboard";
@import "../default/modules/corpus";
@import "../default/modules/graph";
@import "../default/modules/agenda";
@import "../default/modules/configuration";
@import "../default/modules/basic_page";
@import "../default/modules/home-page";
@import "../default/modules/mindmap";
@import "../default/modules/progress-bar-generic";
@import "../default/modules/timeline";
@import "../default/modules/notepad";
@import "../default/modules/faq";

// --------------------------------------------------
// SHARED THEME
// --------------------------------------------------

#lessons {
    @import "../shared_theme/shared_core/button";
    @import "../shared_theme/layout/activities/app-generic-stepper";
}

// --------------------------------------------------
// SAPIENS THEME
// --------------------------------------------------

// Core
@import "core/core";

// Modules
@import "modules/modules";

// --------------------------------------------------
// ACTIVITIES MODULE
// --------------------------------------------------

// Abstracts
//@import "../../@modules/activities/scss/abstracts/a11y";
//@import "../../@modules/activities/scss/abstracts/variables";
//@import "../../@modules/activities/scss/abstracts/functions";
//@import "../../@modules/activities/scss/abstracts/grids";
//@import "../../@modules/activities/scss/abstracts/helpers";
//@import "../../@modules/activities/scss/abstracts/spacing";
//@import "../../@modules/activities/scss/abstracts/radius";
//@import "../../@modules/activities/scss/abstracts/cards";
//@import "../../@modules/activities/scss/abstracts/layouts";

// Base
@import "../../@modules/activities/scss/base/fonts";
@import "../../@modules/activities/scss/base/typography";
@import "../../@modules/activities/scss/base/base";

// Components
@import "../../@modules/activities/scss/components/buttons";
@import "../../@modules/activities/scss/components/audio-player";

.activities-card {
    @import "../../@modules/activities/scss/components/video";
    @import "../../@modules/activities/scss/components/app-zoomable";
    @import "../../@modules/activities/scss/components/activities-card__buttons";
    @import "../../@modules/activities/scss/components/answer";
    @import "../../@modules/activities/scss/components/media";
    @import "../../@modules/activities/scss/components/flashcard";
    @import "../../@modules/activities/scss/components/fields";
}

// Layout
#lessons {
    @import "../../@modules/activities/scss/layout/player/app-stepper-wrapper";
    @import "../../@modules/activities/scss/layout/player/app-multi";
    @import "../../@modules/activities/scss/layout/player/app-progress-bar-generic";
    @import "../../@modules/activities/scss/layout/player/app-progress-bar-multi-zone";
    @import "../../@modules/activities/scss/layout/player/stepper-wrapper__header";
}

.activities-card {
    @import "../../@modules/activities/scss/layout/activities/acti__mat-card";
    @import "../../@modules/activities/scss/layout/activities/acti__mat-card-header";
    @import "../../@modules/activities/scss/layout/activities/acti__mat-card-content";
    @import "../../@modules/activities/scss/layout/activities/acti__mat-card-footer";
    @import "../../@modules/activities/scss/layout/activities/app-consignes";
}

@import "../../@modules/activities/scss/layout/typologies/app-what-is-missing-and-audio";
@import "../../@modules/activities/scss/layout/typologies/true-false";
@import "../../@modules/activities/scss/layout/typologies/app-multimedia";
@import "../../@modules/activities/scss/layout/typologies/app-text-matching";
@import "../../@modules/activities/scss/layout/typologies/app-order-matching";
@import "../../@modules/activities/scss/layout/typologies/app-memory";
@import "../../@modules/activities/scss/layout/typologies/app-memory-find-zone";
@import "../../@modules/activities/scss/layout/typologies/app-voice-recorde-activity";
@import "../../@modules/activities/scss/layout/typologies/app-image-zoning";
@import "../../@modules/activities/scss/layout/typologies/_app-fill-in-blanks-child";

// Pages
@import "../../@modules/activities/scss/pages/_summary-sub-lesson";
@import "../../@modules/activities/scss/pages/_fuse-app-lessons-tab";