/******************  color ******************/
/********* header (Banners) *********/

.page-layout.simple >,
.page-layout.simple.tabbed >,
.page-layout.simple.fullwidth >,
.page-layout.simple.inner-sidebar >,
app-corpus-display,
app-basic-page {

  .header {
    height: 74px; // TODO no constraints
    min-height: 74px;
    padding: 24px;

    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    max-height: 100%;
    place-content: center flex-start;
    align-items: center;

    h2 {
      font-weight: 700;
    }
  }

}
