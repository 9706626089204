 app-basic-page {
     &.comment-ca-marche {
         height: 100%;

         .content{
             height: 100%;

             iframe{
                 width:100%;
                 height: 100%;
             }
         }
     }
    .page-layout.simple {
        padding: 16px;
        .header{
            padding: 16px 0 !important;
        }
    }

     iframe{
         border:0;
     }
}
