@use '@angular/material' as mat;
.mat-column-nickname, .mat-column-pseudo, .mat-column-username {
    max-width: 160px;
}


app-group {
    .mat-column-code {
        p {
            background-color: mat.get-color-from-palette($primary);
            display: inline-block;
            margin: 0;
            padding: 8px 27px;
            color: map_get($mat-white, 500);
            border-radius: 1.5em;
            text-transform: uppercase;
        }

        &.disabled p {
            background: rgba(104, 33, 122, 0.5);
            cursor: not-allowed;
        }
    }
}

app-users-import {
    mat-form-field {
        width: fit-content;
        min-width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 11px;
        }

        .mat-form-field-label-wrapper {
            overflow: inherit;
        }
    }
}

app-graph-group-management {
    .selected-exercice {
        color: map_get(mat.$grey-palette, 500);
        // font-family: $typography-font-name2;
    }

    .statistics-button {
        @extend %mat-stroked-button-primary;
        margin-left: auto !important;
    }

}

fuse-groups-list {
    > .page-layout {
        max-height: 100%;

        > .content {
            flex: unset !important;

            tr & {
                flex: none;
            }
        }
    }
}


.id-groups-form-dialog {
    .dialog-content-wrapper .mat-dialog-content form .mat-form-field-label {
        font: 500 14px/16px Quicksand, Roboto, sans-serif; // mv theme
        color: map_get($primary, 900); // mv theme
    }

    .mat-chip.mat-standard-chip .mat-icon {
        background-color: transparent;
        color: white;
        opacity: 1;
    }

    .dialog-content-wrapper {
        .mat-dialog-actions {
            min-width: 66px;
        }
    }
}


#groups-list-table .groups-inline-modal-learner,
.groups-inline-modal {
    gap: 15px;
    padding: 15px 4px;

    .id-groups-form-dialog {
        margin-bottom: unset;
    }

    .mat-dialog-actions {

        .add {
            gap: 20px;
            height: unset;
            padding: 15px 20px;
            margin: 0 20px;
            border-radius: 40px !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        }

        .add,
        .mat-icon {
            color: var(--typo);
        }
    }

    .id-groups-form-dialog .dialog-content-wrapper .mat-dialog-actions button .mat-icon {
        color: var(--typo) !important; // Override default theme style
    }

    .mat-chip.mat-standard-chip.mat-chip-disabled {
        opacity: 1;
    }

}

.groups-inline-modal {

    tr & { // in component in table
        padding: 0;
    }

    .save-button[disabled] .mat-icon svg path { // TODO remove default comportment with prefix for fill icons and stroke icons
        fill: none;
    }
}

// background: linear-gradient(180deg, rgba(131, 223, 255, 0) 0%, #68217A 100%);

fuse-groups-list {


    .mat-table {
        background: transparent;
    }

    .mat-row-editing {
        box-shadow: none;
    }

    #groups-list-table {
        content .page-layout {
            background-color: transparent !important;
        }

        [svgicon="keyboard_arrow_up"], [svgicon="keyboard_arrow_down"] {
            border: 2px solid #FFA615;
            border-radius: 1em;
        }

        th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
            padding-right: 8px;
        }

        > .mat-table {
            > tbody {

                .mat-row-editing {
                    box-shadow: none;

                    .mat-column-addingLearner {
                        padding: 0 !important;

                        app-learner.groups-inline-modal-learner {
                            //background-color: red !important;
                            background: rgb(131, 223, 255);
                            //background: linear-gradient(0deg, rgba(131,223,255,0) 0%, rgba(104,33,122,1) 100%);
                            background: linear-gradient(0deg, rgba(131, 223, 255, 0) 0%, rgba(104, 33, 122, 0.1));

                            .add {
                                margin-top: 9px;
                                margin-left: 25px;
                                margin-right: 25px;
                            }

                            module-groups-listing {
                                #entity {
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }
                }
            }

        }

        .mat-column-addingLearner {
            // padding: 0 25px;
        }
    }

    .groups-inline-modal {

    }

}

.mat-column-addingLearner {
    background: none !important; // mv theme
}

module-groups-listing > #entity {
    padding: 50px 30px !important;
}

fuse-group-list {
    content .page-layout {
        background-color: transparent !important;
    }
}

/*
*   Edit User dialog
*/

app-institution-users {
    display: flex;
    flex-direction: column;
    margin: 0 35px;

    button.add-user {
        background: mat.get-color-from-palette($accent, 500);
        width: 100%;
        order: 2;
        flex: 1;
        color: white;
        align-items: center;
        display: flex;
        height: 62px;
        border-radius: 10px;
        margin-top: 5px;
        justify-content: center;
        align-content: center;
    }
    .mat-paginator {
        order: 3;
    }

    table {
        order: 1;
        margin-top: 9px !important;
        thead::after, thead::before {
            width: 100%;
            z-index: -1;
        }
        tbody {
            tr {
                td.icon-custom {
                    mat-icon {
                        width: 42px;
                        height: 30px;

                        path {
                            fill: rgba(104, 33, 122, 0.1);
                        }

                        &:first-child {
                            margin-right: 10px;
                        }

                        &.selected {
                            svg {
                                rect {
                                    fill: #68217A;
                                    fill-opacity: 1;
                                }

                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }

                td.user-with-none-assigned-class-or-group {
                    color: red;
                }

                button.btn-assign-only {
                    @extend %main-button;
                    min-width: inherit;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    text-align: center;
                    letter-spacing: 0.4px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.edit-user-Component-form-dialog {
    mat-dialog-container.mat-dialog-container.mat-dialog-container {
        width: 100%;
    }
}

app-edit-user {
    .mat-card {
        background-color: #F8F8F8;

    }
    .mat-card-footer {
        button {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            /* line-height: 16px; */
            text-align: center;
            letter-spacing: 0.4px;
            color: #383838;
            border-radius: 5px;
        }
    }

    .mat-dialog-title {
        margin-bottom: 0;
        h2 {
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 16px;
            letter-spacing: 0.4px;
        }
    }

    .mat-card-header {
        display: none;
    }

    .edit-user-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        place-content: flex-start flex-start;


        .field-container {
            height: 100%;
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: center flex-start;
            align-items: center;
            width: 100%;

            mat-label {
                flex: 2;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.4px;
                &.role-selected {
                    color: rgb(104, 33, 122);
                }
            }

            .mat-form-field {
                flex: 5;

                mat-error {
                    width: 100%;
                }

                input {
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                }

                mat-chip {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                }
            }

            div.mat-form-field {
                display: flex;
                align-items: center;

                [svgicon="educ-role"], [svgicon="admin-role"] {
                    width: 42px;
                    height: 30px;

                    svg {
                        rect {
                            fill: #CEC5D0;
                            fill-opacity: 0.2;
                        }

                        path {
                            fill: #CEC5D0;
                        }
                    }

                    path {
                        fill: rgba(104, 33, 122, 0.1);
                    }

                    &:first-child {
                        margin-right: 10px;
                    }


                    &.role-selected {
                        svg {
                            rect {
                                fill: #68217A;
                                fill-opacity: 1;
                            }

                            path {
                                fill: #fff;
                            }
                        }
                    }
                }

            }

            mat-slide-toggle {
                margin-right: 10px;
            }


        }

        .assign-to-pending-account {
            padding: 10px;
            background: rgba(56, 56, 56, 0.26);
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.4px;
        }
    }

    .mat-dialog-actions {
        justify-content: flex-end !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 5px 0 15px 0;
    }
    .edit-user-container .field-container mat-label {
        padding-left: 16px;
        flex: 2;
        font-weight: bolder;
    }
}



.groups-inline-modal {
    button {
        border-radius: 5px !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 0px #E3A52C, 0px 2px 2px rgba(0, 0, 0, 0.1);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07);
        letter-spacing: 0.4px;
    }

}

app-institution-information {
    .container {
        display: flex;
        flex-direction: column;
        padding: 24px;
        height: calc(100% - 49px);
    }

    .header {
        position: inherit;
        min-height: 70px;
        background-color: #68217a;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.4px;
        height: 60px;
        border-top: 1px solid #E5E5E5;
        border-right: 1px solid #E5E5E5;
        border-left: 1px solid #E5E5E5;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px 5px 0 0;

        .date {
            font-weight: 100;
            font-size: 13px;

            span {
                font-size: 13px;
                font-weight: 600;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        background-color: #FFFFFF;
        height: 100%;
        border-bottom: 1px solid #E5E5E5;
        border-right: 1px solid #E5E5E5;
        border-left: 1px solid #E5E5E5;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        border-radius: 0 0 5px 5px;

        .content-row {
            margin-top: auto;
            margin-bottom: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 24px 10px 13px; // 13 because mat icon have 11.5 px margin so to make 24px padding add only 13px

            span, b {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.4px;
                color: #68217A;

                mat-icon {
                    min-width: 40px;
                    min-height: 40px;

                    rect {
                        fill: none;
                    }
                }
            }

            button {
                @extend %main-button;
            }

            .multiple-row {
                flex-direction: column;
                width: 100%;
            }

            .info {
                overflow-wrap: break-word;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: 13px;
                flex-wrap: wrap;
                padding-top: 10px;

                span {
                    display: block;
                    font-weight: 700;
                    width: 100%;
                }

                b {
                    display: block;
                }
            }

            .toogle-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 15px 0 15px 0;

                .school-code {
                    text-transform: uppercase;
                    background: #68217A;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    border-radius: 100px;
                    padding: 10px 15px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: white;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.4px;
                    }

                    .code {
                        font-weight: 700;
                    }
                }

                .school-code-disabled, .school-code-class-disabled {
                    background: #9e9e9e;
                }
            }

            mat-slide-toggle {
                margin-right: 30px;
                padding-left: 13px;
            }
        }

        .content-row-separator {
            width: calc(100% - 20px);
            height: 1px;
            background: #E5E5E5;
            margin-left: 20px;
            padding-top: 1px;
        }
    }
}