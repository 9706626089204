#app-feedback {
    form {
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: white;
        padding: 30px;
        height: 92vh;
        width: 70vh;

        img {
            height: 15vh;
            margin: auto;
        }

        textarea {
            height: 25vh;
            margin-bottom: 20px;
        }

        button.mat-raised-button {
            margin: auto;
            height: 5vh;
        }
    }
}

fuse-register {
    /**
    to show info message at bottom of field
     */
    .info-mail {
        position: absolute;
        margin: auto;
        display: block;
        transform: translate(-14px, 18px);
        font-size: 12px;
    }

    .mat-form-field-label-wrapper {
        margin-left: 2rem;
    }

    .mat-form-field-should-float {
        .mat-form-field-label-wrapper {
            margin-left: 0rem;
        }
    }

    .mat-checkbox {
        margin-top: 1.5rem !important;

        &:first-child {
            margin-top: 3rem !important;
        }
    }

    .you-are {
        order: 1;
    }

    .level {
        order: 2;
    }

    .find-us {
        order: 3;
        flex: 1 1 50%;
        box-sizing: border-box;
        max-width: 50%;
    }

    .checkboxes {
        order: 4;
    }

    .mat-form-field-subscript-wrapper {
        display: contents !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0.25em !important;
    }

    mat-error {
        padding-top: 5px;
    }

    .frc-captcha{
        bottom: 204px;
    }

    #register #register-form .checkboxes .submit-button{
        margin-top: 102px !important;
    }
}

%default-text-style {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #323A49;
}

#about {
    background: #fcfcfc;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin: 30px;
    padding: 0 !important;
    max-width: 100% !important;

    .info-box {
        .content {
            padding: 16px 32px 8px 16px !important;
        }
    }

    // part consulting profile
    .about-content {
        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $primary-c !important;
            color: #ffffff !important;
            border-radius: 5px 5px 0 0;
            padding: 17px 27px;

            .title {
                text-transform: uppercase;
            }

            .attached-info {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .info-line {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 8px !important;

            .title {
                @extend %default-text-style;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-transform: uppercase;
            }

            .info {
                @extend %default-text-style;
                min-height: 35px;
                font-weight: 400;
            }
        }

        .mat-card-actions {
            place-content: flex-end !important;
            padding-right: 32px;
            background-color: #fcfcfc;
            margin-left: 0;
            margin-right: 0;

            button {
                min-width: 190px !important;

                mat-icon {
                    width: 17px !important;
                    height: 17px !important;
                    min-width: 17px !important;
                    min-height: 17px !important;
                    margin-left: 10px;
                }

                span {
                    flex-direction: row-reverse;
                    align-items: flex-end !important;
                }
            }
        }

        .content {
            background-color: #fcfcfc !important;
        }
    }
}

// part editing profile
#forms {
    padding: 31px !important;

    .mat-form-field-label {
        display: flex;
        align-items: center;
        transform: none !important;
        width: 100%;
        min-height: 35px;
    }

    form {
        max-width: 100% !important;
        padding: 0 !important;
        background: #fcfcfc;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        // header
        .h2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 75px;
            background-color: #28458c !important;
            color: #ffffff !important;
            border-radius: 5px 5px 0 0;
            font-weight: 800;
            font-size: 18px;
            line-height: 20px;
            padding: 35px;
            margin-bottom: 0 !important;
        }

        .mb-24 {
            margin-bottom: 0 !important;
        }

        mat-form-field {
            display: flex;
            position: inherit;
            flex-direction: row;
            align-items: center;
            padding-left: 15px;
            width: 100%;

            .mat-form-field-wrapper {
                width: 100%;
                margin-right: 27px;
                padding-bottom: 0;

                .mat-form-field-infix {
                    display: flex;
                    flex-direction: row-reverse;
                    position: inherit;
                    flex: inherit;
                    min-width: 0;
                    width: 100%;
                    padding: 0;
                }
            }

            .mat-form-field-underline {
                display: none;
            }

            .mat-form-field-label {
                span {
                    @extend %default-text-style;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                }
            }

            .mat-form-field-label-wrapper {
                flex: 1;
                position: inherit;
            }

            input,
            mat-select {
                background: #ffffff;
                border: 1px solid #e5e5e5;
                box-sizing: border-box;
                border-radius: 3px;
                color: #3d3d3d;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
                min-height: 35px;
                padding: 11px;
                flex: 2;
            }
        }

        .upload-file-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 15px;

            em,
            small {
                padding-top: 1px;
            }
        }

        app-upload-file {
            padding: 0;
            padding-left: 7px; // for moment because we hide icon

            form {
                max-width: inherit !important;
                padding: inherit !important;
                background: inherit !important;
                border: none !important;
                box-shadow: none !important;
                border-radius: 0 !important;

                label {
                    @extend %default-text-style;
                    font-weight: 700;
                    align-items: center;
                    margin-right: 15px;
                    margin-left: 1px;
                }
            }
        }

        .save-button,
        .cancel-button {
            width: 250px;

            span {
                justify-content: center !important;
            }
        }

        .cancel-button {
            margin-bottom: 35px;
        }
    }

    .content {
        padding: 0 !important;

        div {
            display: flex;
        }
    }
}

.about-content {
    max-width: 100% !important;
    background-color: #fcfcfc;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

fuse-profile-about .profile-box {
    box-shadow: none !important;
}

fuse-profile {
    .mat-tab-header {
        display: none !important;
    }
}

.content-row-separator {
    width: calc(100% - 26px);
    height: 1px;
    background: #e5e5e5;
    margin-left: 20px;
    padding-top: 1px;
}

app-join-school {
    background-color: #fcfcfc;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 135px;
        padding-left: 16px;
    }

    .title {
        @extend %default-text-style;

        span {
            font-weight: 800;
            display: flex;
            align-items: center;
            padding-top: 10px; // because icon is hide for moment
        }

        mat-icon {
            display: none; //to hide for moment icon because only one and must have multiple one
            min-width: 40px;
            min-height: 40px;

            rect {
                fill: none;
            }
        }
    }

    .content {
        @extend %default-text-style;
        padding-left: 11px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    span {
        font-weight: 500;

        b {
            @extend %default-text-style;
            font-weight: 800;
        }
    }

    .join-zone {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 32px;
        padding-top: 16px;
        width: 90%;

        button {
            @extend %main-button;
            min-width: auto;
            height: 40px;
            margin-left: 8px;

            span.mat-button-wrapper {
                min-width: 130px !important;

                mat-icon {
                    margin-left: 10px;
                    width: 20px !important;
                    height: 20px !important;
                }
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;

                span.mat-button-wrapper {
                    cursor: not-allowed;

                    mat-icon {
                        svg {
                            path {
                                fill: #fcb731 !important;
                            }
                        }
                    }
                }
            }
        }

        span {
            @extend %default-text-style;
        }

        .school-joined {
            @extend %default-text-style;
            color: white;
            background: $primary-c;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 100px;
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .leave {
            @extend %red-button;
        }

        input {
            background: #ffffff;
            border: 1px solid #e5e5e5;
            height: 40px;
            box-sizing: border-box;
            box-shadow: inset 0 2px 0 rgba(229, 229, 229, 0.5);
            border-radius: 3px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: rgba(61, 61, 61, 0.51);
            padding: 10px;
            min-width: 210px;
        }

        mat-error {
            padding-left: 8px;
        }

        mat-spinner {
            margin-left: 5px;

            svg {
                circle {
                    stroke: white;
                }
            }
        }
    }
}

fuse-profile-about {
    .avatar-container {
        max-width: 200px !important;
        min-width: 200px !important;
    }

    .avatar {
        width: 200px !important;
        height: 200px !important;
    }
}


blocked-account {

    #mail-confirm {
        width: 100%;
        overflow: auto;
        background: image('backgrounds/dark-material-bg.jpg') no-repeat;
        background-size: cover;

        #mail-confirm-form-wrapper {
            flex: 1 0 auto;
            padding: 32px;

            @include media-breakpoint('xs') {
                padding: 16px;
            }

            #mail-confirm-form {
                max-width: 480px;
                padding: 48px;
                background: #FFFFFF;
                text-align: center;

                @include media-breakpoint('xs') {
                    padding: 24px;
                    width: 100%;
                }

                .logo {
                    margin: 0 auto 32px auto;
                }

                .title {
                    font-size: 20px;
                    margin-top: 16px;
                }

                .subtitle {
                    margin: 16px auto;
                    max-width: 300px;
                    color: rgba(0, 0, 0, 0.54);
                    font-size: 15px;
                }

                .message {
                    font-weight: 500;
                    text-align: center;
                    margin-top: 40px;
                }
            }
        }
    }
}