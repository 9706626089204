%mat-tab-label {
    background-color: transparent;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: none !important;
    font-size: 18px;
}

.mat-tab-label {
    @extend %mat-tab-label;
}

.mat-tab-nav-bar,
.mat-tab-header {
    border-bottom: none;
}

.mat-tab-nav-bar.mat-primary {
    .mat-tab-link {
        color: map-get($primary, 900);
        opacity: 1;
    }
    .mat-ink-bar {
        background-color: $accent-c;
        height: 4px;
    }
}

mat-tab-group.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $accent-c;
}
