app-only-model-lessons-list {
    position: relative;
    overflow: hidden !important;
    background-color: #3a1d10;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 40%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: icon('ripped_paper_sidebar.svg') no-repeat;
        background-size: cover;
        box-shadow: inset 0 20vh 0 white;
        background-position: 0 100%;
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: image('backgrounds/bg_lessons.png') no-repeat 0 0;
        background-size: 100% auto;
        transform: rotate(4.5deg) translateY(-6%) scale(1.3);
    }

    fuse-app-lessons-tab {
        z-index: 1;
        padding: unset !important;

        &::before { // Logo
            content: "";
            display: block;
            width: 70vh;
            height: auto;
            padding-top: calc(70vh * 0.15);
            position: absolute;
            top: 6%;
            left: 50%;
            background-image: url(/assets/sapiens/icons/brand.svg);
            background-size: cover;
            background-repeat: no-repeat;
            transform: translateX(-50%);
        }

        .mat-progress-spinner {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .cards.content {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
            grid-template-rows: auto;
            justify-items: center;
            gap: 30px;
            padding: 40vh 30px 30px 30px;

            app-card {
                width: 100%;
                max-width: 900px;

                .mat-card {
                    position: relative;
                    width: 100%;
                    background-image: image('backgrounds/bg_popup_dialog.jpg');
                    background-size: cover;
                    background-position: top left;
                    background-repeat: no-repeat;
                    box-shadow: 0px 0px 30px -7px rgba(255, 255, 255, 1);

                    &::after { // Add an ::after pseudo-element to simulate the semi-transparent border
                        content: '';
                        display: unset;
                        position: absolute;
                        z-index: 2;
                        inset: 0;
                        width: unset;
                        height: unset;
                        padding: 2px;
                        border-radius: inherit;
                        background-color: var(--noir-10, rgba(0, 0, 0, 0.10));
                        mask: linear-gradient(#FFF 0 100%) content-box exclude,
                              linear-gradient(#FFF 0 100%) border-box add;
                        pointer-events: none;
                    }

                    .mat-card-header {
                        position: relative;
                        z-index: 1;

                        &:before {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            top: 28px;
                            left: 0;
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: url(/assets/sapiens/icons/ripped_paper_2.svg) no-repeat;
                            background-size: 100% 110%;
                            background-position: 60% 100%;
                        }

                        .mat-card-header-text {
                            color: var(--typo);
                        }
                    }

                    .mat-card-image {
                        border: unset;
                    }

                    .mat-card-content {
                        background: unset;

                        dl.dl-list {
                            flex: 1 !important;
                            justify-content: center;
                            padding: 20px 10px 0 10px;

                            > div {

                                dt {
                                    display: none;
                                }

                                dd {
                                    box-sizing: border-box;
                                    flex: 1 !important;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    color: var(--blanc);
                                    font-style: normal;
                                }

                                &:not(:first-child) { // Only display the first div
                                    display: none !important;
                                }
                            }

                            &.keywords-list {
                                display: none;
                            }
                        }
                    }

                    .mat-card-actions {
                        background: unset;

                        button {

                            &.button-download-theme {
                                justify-content: center;
                                margin-right: auto;
                                padding: 0.5rem;
                                border-radius: 50%;

                                span {
                                    display: none; // Hide label
                                }
                            }

                            &.button-play {
                                flex-direction: row-reverse;
                            }
                        }
                    }
                }

                &:nth-child(2) { // Hide the second card under the 'Coming soon' layer (to remove when the card is ready to be displayed)

                    .mat-card {
                        pointer-events: none;

                        &::before {
                            content: 'Coming soon';
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            z-index: 2;
                            inset: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            background-color: rgba(222, 224, 227, 1);
                            background-image: image('monkey.png'),
                                              image('backgrounds/coming-soon-background.svg');
                            background-size: auto 50%,
                                             80% auto;
                            background-position: bottom right 10px,
                                                 center;
                            background-repeat: no-repeat,
                                               no-repeat;
                            font-size: 2.4rem;
                            font-weight: 700;
                            text-transform: uppercase;
                        }

                        &::after {
                            z-index: 3;
                        }
                    }
                }

                &:nth-child(n + 3) { // Only display the first two cards
                    display: none !important;
                }
            }
        }
    }

    .mat-paginator {
        display: none;
    }
}