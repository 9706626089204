@use '@angular/material' as mat;
%header-expansion-panel { // TODO bad css why so much custom color on default ? should be override in many themes
    background-color: mat.get-color-from-palette($primary);
    color: white;
}

 mat-expansion-panel
{
    &.mat-expansion-panel {
        .mat-expansion-panel-header {
            height: 52px;
            .mat-icon {
                &[svgicon="expand"] path {
                    fill: white;
                }
            }
            &:focus, &:hover {
                @extend %header-expansion-panel;
            }
        }
        &:not(.mat-expanded) {
            .mat-expansion-panel-header:not([aria-disabled='true']) {
                &:focus, &:hover {
                    @extend %header-expansion-panel;
                }
            }
        }
    }
    &.mat-expansion-panel:not(.mat-expanded) {
        .mat-expansion-panel-header .mat-icon {
            transform: rotate(90deg);
        }
    }
    &.mat-expansion-panel.mat-expanded {
        .mat-expansion-panel-header .mat-icon {
            transform: rotate(-90deg);
        }
    }

    .mat-expansion-indicator {
        display: none;
    }
}
