@use '@angular/material' as mat;

$color-header: $white-c;

.mat-card.mat-card {
    .card-split-media-info & {
        min-height: 278px;
        background-color: map-get($mat-white, 500);
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 5px;
        overflow: hidden;

        mat-card-header {
            background-color: var(--blanc);

            z-index: 10;

            .mat-card-title * {
                font-size: 20px;
                font-weight: 700 !important;
                margin: 3px 0;
                color: var(--typo);
            }

            .pastille {
                display: none;
            }

            mat-icon {
                path {
                    fill: white;
                }
            }
        }

        .mat-card-image {
            width: 226px;
            min-width: 226px;
            max-width: 226px !important;
            height: 226px;
            border: 5px solid #05A8AA;
            position: relative;
        }

        .mat-card-content {
            min-height: 174px;
            margin-left: 0;
            flex: 1;
            padding: 10px;
            flex-direction: column;
            display: flex;
            overflow: auto;
            height: 174px !important;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.2;

            .dl-list {
                margin-bottom: 0 !important;
                flex: none !important;
            }

            dt {
                text-transform: uppercase;
            }

            .keywords-list {
                margin-top: 0;
            }

            .keywords {
                display: flex;
                flex-wrap: wrap;

                dt {
                    color: white;
                    background: #05A8AA;
                    margin: 5px;
                    border-radius: 15px;
                    padding: 3px 7px 3px 7px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }

                dt::after {
                    content: "";
                    padding-right: 0;
                    margin-left: 0;
                }
            }

            dd {
                margin: 0;

                &::after {
                    content: none;
                }
            }

            .get-date-label dt {
                display: block;
            }

        }

        .mat-card-actions {
            flex: none;
            margin-left: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 226px);
            padding: 8px !important;

            button {
                @extend %mat-stroked-button-accent;
                flex: none;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.5rem 1.5rem;
                border-radius: 20px;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

                .mat-icon {
                    flex: none;
                    width: 2.5rem;
                    min-width: unset;
                    height: auto;
                    min-height: unset;
                    aspect-ratio: 1;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

app-card.card-split-media-info .mat-card mat-card-actions {
    justify-content: flex-end;
}