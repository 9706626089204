@use '@angular/material' as mat;
.count-entities {
    font: $form-field-input-font;
}


app-shared-filters .filters {
    /* Temporary Workaround:
 * changing the width of dropdown
 */
    //.cdk-overlay-connected-position-bounding-box {

        //.cdk-overlay-pane {
        //    transform: none !important;
        //    overflow-x: hidden;
        //    box-sizing: content-box;
        //    padding: 4px 4px 10px 5px;
        //    margin-left: -5px;
        //    margin-top: -2px;
        //
        //    .mat-select-panel {
        //        margin-top: 27px;
        //        background: #fff;
        //        max-height: 300px;
        //        //min-width: 100% !important;
        //        min-width: 50px !important;
        //    }
        //}
    //}
    .mat-toolbar-row, .mat-toolbar-single-row {

        mat-form-field.mat-form-field {
            max-width: 100%;
        }

        gap: 0 !important;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 0;

        &:first-child {

        }

    }


}

.filters {

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    box-shadow: 0 12px 40px -25px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .filter-date {
        flex: 2;
    }

    @extend %mat-form-field-label-on-top;

    mat-form-field.mat-form-field .mat-form-field-infix .mat-input-element {
        font-size: 16px !important;
        padding: 0 !important;
    }

    input.mat-chip-input {
        width: 50px;
        flex: 1 0 110px;
    }


    mat-form-field.mat-form-field {
        flex: 1;
        max-width: 50%;
        margin: 0 !important;



        .mat-form-field-infix {
            padding: 0 0 7px 0 !important;
            .mat-input-element {
                padding: 0 8px;
            }
        }
    }

    .mat-form-field.mat-form-field-should-float,
    .mat-form-field.mat-form-field-can-float,
    .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
        margin-right: 10px;

        &.field-school {
            min-width: 190px;
        }
    }

    button.mat-raised-button {
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(255, 255, 255, 0.09);
        border-radius: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.4px;

        .count {
            margin-right: -13px;
            padding: 0 5px;
        }
    }

     &.isCollapsed {
        .mat-toolbar-row:first-child, .mat-toolbar-single-row:first-child {
            button.mat-raised-button {
                opacity: 0;
                transition: 1s opacity ease;
            }
        }
    }

    .mat-toolbar-row, .mat-toolbar-single-row {
        font-family: #{$font-family};

        > * {
            flex: 1;
        }
        .mat-form-field-underline {
            background-color: rgba(0, 0, 0, 0.4);
        }

        &:first-child {
            gap: 3%;
            justify-content: space-between;
            //.isCollapsed & {
            button.mat-raised-button {
                opacity: 1;
                transition: 1s opacity ease;
            }
            //}

            @include media-breakpoint(md) {
                justify-content: space-between;
            }
        }
        &:last-child {
            gap: 3%;
            app-learner &  {
                padding-right: calc( 186px + 3%); // size of above button and gap
            }
        }

    }

    .btn-collapse {
        color: $default-font-color;


        span {
        }

        .mat-icon {
            @include sizeFix(32px);
            border: 1px solid $default-font-color;
        }

        &:not(.mat-stroked-button) {
            color: $default-font-color;
            background-color: white;
            padding: 0 0 0 20px;

        }
    }

    // TODO remove :not selector :(
    button:not(.mat-stroked-button) svg.mat-datepicker-toggle-default-icon > path:first-child {
        fill: mat.get-color-from-palette($mat-white, 500);
    }

}


