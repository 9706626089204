app-multimedia {
    // il ne se met pas en 2 colonnes sur desktop par default il faut changer le comportement par default de mat-card-content

    .activities-card > mat-card-content.mat-card-content {
        overflow: auto;
    }
    @include media-breakpoint-up('sm') {

        .activities-card > mat-card-content.mat-card-content {
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            display: flex;
        }


    }


}