@use '@angular/material' as mat;
 app-projects-dashboard {
    .iconEmptyWidget{
        svg {
            path{
                fill: mat.get-color-from-palette($accent);
            }
        }
        width: 150px;
        height: 150px;
        font-size: 150px;
    }

}
