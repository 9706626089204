// peut être

.row {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.two-third, .one-third, .full {
  display: flex;
  flex-wrap: wrap;
}
.two-third {
  width: calc(100% / 3 * 2);
}
.one-third {
  width: calc(100% / 3 * 1);
}
.one-two {
  width: calc(100% / 2 * 1);
}



.full {
  width: 100%;
}