.mat-toolbar-multiple-rows.filters {
    min-height: inherit;
}

// Top site banner
#container-3 {

    > toolbar {
        background-color: var(--faux_blanc);
        border-bottom: 1px solid var(--gris_de_contour);

        body.player & {
            display: none;
        }

        .mat-toolbar-row,
        .mat-toolbar-single-row {
            height: inherit;
            min-height: 72px;
        }

        .toolbar__home-pseudo-button {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 8px 16px;
            cursor: pointer;

            .toolbar__home-icon-svg,
            .toolbar__home-icon-png {
                width: 142px;
                height: auto;
            }

            .toolbar__home-chevron {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 14px;
                min-width: unset;
                height: 14px;
                min-height: unset;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .toolbar-separator {
            display: none;
        }

        .user-button {
            background: transparent;
            border: none;
            box-shadow: none;
            border-radius: 6px;
            .account-info {
                display: flex;
                .avatar {
                    order: 2;
                }
                .account-labels {
                    order: 1;
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                }
            }
            .mat-icon {
                color: var(--orange);
            }
        }
    }
}