#moduleAchievement {
    header {
        margin-left: auto;
        margin-right: auto;

        nav {
            a {
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }

    main {
        #myProfile {
            width: 100%;
            display: grid;
            grid-template:
                'avatar avatar'
                'openBadges achievements' / 1fr 1fr;
            column-gap: 12rem;
            max-width: 100rem;
            margin-left: auto;
            margin-right: auto;
            padding-top: 4rem;

            section {
                h2 {
                    font-weight: 700;
                }
            }

            .card {
                padding: 0;
                aspect-ratio: 1/1;
                background-color: #fff;
                border: 0.1rem solid #c6ccd7e5;
                border-radius: 0.75rem;
                box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.2);
            }

            .avatar__container {
                grid-area: avatar;
                display: flex;
                justify-content: center;
                align-items: center;

                padding-top: 2rem;
                padding-bottom: 2rem;

                .avatar__picture {
                    position: relative;
                    width: 25%;

                    h2 {
                        position: absolute;
                        left: 50%;
                        width: 80%;
                        transform: translate(-50%, -50%);
                        top: -1rem;

                        padding: 1rem 2rem;

                        border: $accent-c 0.25rem solid;
                        border-radius: 0.75rem;
                        background: $primary-c;
                        color: white;
                        font-size: 2rem;
                        text-align: center;
                    }

                    div {
                        display: grid;
                        place-content: center;
                        cursor: pointer;
                        border: $primary-c 0.5rem solid;
                    }
                }

                .avatar__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    width: 45%;
                    height: 100%;

                    background-image: url('/assets/amazon_python/images/backgrounds/avatar.svg');
                    background-size: contain;
                    background-repeat: no-repeat;

                    ul {
                        width: 90%;
                        padding: 1.5rem 3rem;
                        margin-left: -1rem;
                        margin-top: 0;

                        list-style: none;
                        background-color: $primary-c;
                        border: $accent-c 0.25rem solid;
                        border-left: none;
                        border-radius: 1rem;

                        box-shadow: 0px 9px 13px 0px rgb(0 0 0 / 1%);

                        li {
                            display: flex;
                            justify-content: space-between;

                            margin-bottom: 0.75rem;
                            padding: 0.5rem 2rem;
                            padding-right: 0.5rem;

                            background: white;
                            border-radius: 2.5rem;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            span {
                                font-weight: bold;
                                font-size: 2rem;
                                font-style: italic;

                                &:last-child {
                                    padding-left: 1.5rem;
                                    padding-right: 1.5rem;
                                    border-radius: 2rem;
                                    background-color: $accent-c;
                                    color: white;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            #openBadges {
                grid-area: openBadges;

                ul {
                    grid-template-columns: repeat(
                        auto-fill,
                            minmax(12rem, 1fr)
                    );

                    li {
                        cursor: pointer;
                    }
                }
            }

            #achievements {
                grid-area: achievements;

                ul {
                    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));

                    li {
                        cursor: pointer;

                        &:hover {
                            border: $accent-c 0.25rem solid;
                        }
                    }
                }
            }

            #openBadges,
            #achievements {
                ul {
                    display: grid;
                    gap: 2.5rem 1.5rem;

                    padding: 0;

                    li {
                        list-style: none;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }
        }

        #myCity {
            display: flex;
            flex-direction: column;

            padding: 1rem;
            height: 100%;

            iframe {
                border-radius: 1rem;
                width: 100%;
                height: 100%;
            }
        }

        #leaderboard {
        }

        #my-ranking {
            display: inline-flex;
            flex-wrap: wrap;
            margin: 10px 10px 30px 10px;
            justify-content: flex-start;
            background-color: $primary-c;
            border-radius: 24px;
            @include media-breakpoint-up('sm') {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            h2 {
                flex: none;
                display: flex;
                align-items: center;
                padding: 0 20px;
                margin: 0;
                color: white;
                font-size: 18px;
                font-weight: normal;
                text-transform: uppercase;

            }

            .my-ranking__list {
                display: flex;
                margin: 0;

                background-color: white;
                border: 5px solid $primary-c;
                box-shadow: 0 0 15px rgb(0, 0, 0, 0.5);
                border-radius: 24px;
                font-weight: 700;
                font-style: italic;
                color: #3A4354;

                flex: 1;
                @include media-breakpoint-up('sm') {
                    flex: none;
                }


                dd {
                    margin: 0;
                    &:before, &:after {
                        display: none;
                    }
                    &:first-of-type {
                        border-top-left-radius: 24px;
                        border-bottom-left-radius: 24px;
                    }
                    &:last-of-type {
                        border-top-right-radius: 24px;
                        border-bottom-right-radius: 24px;
                        margin-left: auto;
                    }
                }
            }

            .my-ranking__list__name {
                font-size: 27px;
                background-color: white;
                padding: 0 2%;
                @include media-breakpoint-up('sm') {
                    padding: 0 50px;
                }
            }

            .my-ranking__list__points {
                font-size: 27px;
                padding: 0 3%;
                white-space: nowrap;
                @include media-breakpoint-up('sm') {
                    padding: 0 70px;
                }
            }

            .my-ranking__list__ranking {
                flex: none;
                display: flex;
                justify-content: center;
                align-content: center;
                padding: 0 18px;

                font-size: 19px;
                font-style: normal;
                color: white;
                background-color: #fbac35;
                border: 5px solid #fff;
                border-radius: 24px;
                box-shadow: inset 0 0 1px #b87a1d;
            }
        }
    }
}

app-welcome {
    background: transparent url('/assets/amazon_python/images/backgrounds/onboarding_welcome.png') no-repeat 50% 50%;

    h1 {
        /* <Hello world/> */
        position: relative;
        z-index: 0;
        margin: 0;

        font-weight: 900;
        font-size: 46px;
        line-height: 112px;
        display: flex;
        align-items: center;
        text-align: center;

        /* Orange Amazon */
        color: $accent-c;

        @include media-breakpoint-up('sm') {
            font-size: 96px;
        }

        /* Cartridge under title */
        &:after {
            content: '';
            display: block;
            width: calc(100% - 30px);
            height: calc(100% + -20px);
            margin-left: 20px;
            margin-right: 20px;
            background-color: white;
            position: absolute;
            z-index: -1;
            border: 0.5px solid rgba(198, 204, 215, 0.9);
            box-shadow: 0 9px 13px rgb(0 0 0 / 4%), inset 0 4px 7px #ffffff;
            border-radius: 15px;
            @include media-breakpoint-up('sm') {
                width: calc(100% - 40px);
                height: calc(100% + 40px);
            }
        }
    }

    p {
        /* Rectangle description */
        position: relative;
        min-height: 100px;
        padding: 35px 16%;
        margin-left: 70px;
        margin-right: 70px;
        /* Bleu Amazon */
        background: $primary-c;
        /* Orange Amazon */
        border: 3px solid $accent-c;
        border-radius: 5px;
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;

        @include media-breakpoint-up('sm') {
            font-size: 24px;
        }

        /* Blanc */
        color: #ffffff;
        min-height: 100px;

        &:before,
        &:after {
            position: absolute;
            content: '';
            display: block;
        }

        &:before {
            width: 199px;
            height: 242px;
            background: url(/assets/amazon_python/icons/onboarding_symboles_left.svg) no-repeat;
            background-size: 100px 130px;
            background-position: -1px 37px;
            top: -61px;
            left: -55px;
            @include media-breakpoint-up('sm') {
                background-size: 199px 242px;
                background-position: 0 0;
            }
        }

        &:after {
            width: 161px;
            height: 168px;
            background: url('/assets/amazon_python/icons/onboarding_symboles_right.svg') no-repeat;
            background-size: 101px 128px;
            background-position: 100% 100%;
            right: -64px;
            bottom: -35px;
            @include media-breakpoint-up('sm') {
                background-size: 161px 168px;
                position: absolute;
                right: -62px;
                bottom: 8px;
            }
        }
    }

    button {
        /* lancer l'experience */
        box-sizing: border-box;

        /* Orange Amazon */
        background: $accent-c;
        /* Noir 10% */
        border: 1px solid rgba(0, 0, 0, 0.1);
        /* ombre_bouton */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 15px 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
    }
}

// app-beginning-pop-up, ...
snack-bar-container > div {
    /* H4 Regular 16 */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding-bottom: 15px;

    /* Blanc */
    color: #ffffff;

    /* Bleu Amazon */
    background: $primary-c;
    /* Orange Amazon */
    border: 3px solid $accent-c;
    border-radius: 5px;

    position: relative;

    .header {
        position: absolute;
        top: -31px;
        left: -27px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        .box {
            background: $accent-c;
            border: 2px solid #ffffff;

            border-radius: 4.9539px;
            color: white;
            width: 50px;
            height: 50px;
            font-size: 36px;
            font-weight: 900;
            line-height: 42px;
            letter-spacing: 0em;
            text-align: center;
        }

        h1 {
            /* Orange Amazon */
            background: $accent-c;
            padding: 0 5px;

            background: #fbac35;
            padding: 6px 10px 6px 10px;
            border-radius: 0 4.25px 4.25px 0;
            font-size: 20px;
        }
    }

    .body {
        margin: 40px 25px 15px;
    }

    button {
        /* Orange Amazon */
        background: $accent-c;
        /* Noir 10% */
        border: 1px solid rgba(0, 0, 0, 0.1);
        /* ombre_bouton */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 3.75px;

        min-height: 30px;
        min-width: 100px;
        color: white;
        padding: 7px;
    }
}

snack-bar-container.mat-snack-bar-container {
    background-color: unset;
    box-shadow: unset;
}

app-my-profile {
    .spinner-wrapper {
        width: 100%;
        height: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: center;
    }
}